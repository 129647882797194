import moment from 'moment';

export const nonNullable = <T>(array: T[] | undefined): NonNullable<T>[] => {
    const newArray = new Array<any>();
    if (array !== undefined) {
        for (const item of array) {
            if (item !== undefined) {
                newArray.push(item as any);
            }
        }
    }
    return newArray;
};

export const notNull = <TValue>(value: TValue | null | undefined): TValue | undefined =>
    value === null ? undefined : value;

export const stringToDateOrUndefined = (stringDate: string | null | undefined): Date | undefined =>
    stringDate ? moment(stringDate).toDate() : undefined;

export const stringToDate = (stringDate: string): Date => moment(stringDate).toDate();

export const equalitySortChecker = (data1: number | string, data2: number | string) => {
    if (data1 >= data2)
        return 1;
    else if (data1 <= data2)
        return -1;
    else
        return 0;
};

export const downloadBlobAsFile = (fileName: string, blob: Blob) => {
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
};

export const hashIt = (value: any): string => {
    let hash = 0, i, chr;
    for (i = 0; i < value.length; i++) {
        chr   = value.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};

export const initialize = (startAction: () => Promise<void>): void => {
    void startAction();
};

export const delayAsync = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isNotNullOrUndefined = <TValue>(value: TValue | null | undefined): value is TValue =>
    value !== null && value !== undefined;

export const isNullOrUndefined = <TValue>(value: TValue | null | undefined): value is null | undefined =>
    value === null || value === undefined;

export const isNotEmptyString = (value: string | null | undefined): boolean => {
    return isNotNullOrUndefined(value) && value.trim().length !== 0;
};

export const isEmptyString = (value: string | null | undefined): boolean => {
    return !isNotEmptyString(value);
};