import { makeStyles } from '@material-ui/core/styles';

export const getCoinGameStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
    },
    content: {
        [theme.breakpoints.down('xs')]: {
            padding: '4px',
        },
    },
    contentWithList: {
        padding: '0px'
    },
    column: {
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 6rem)',
        },
        overflowY: 'auto'
    },
    control: {
        padding: theme.spacing(2),
    },
    row: {
        padding: '6px 24px 6px 16px',
    },
    coinText: {
        backgroundColor: '#c07d00',
        color: 'transparent',
        textShadow: '0.5px 0.5px 0.5px #fecb00',
        fontWeight: 'bold',
        position: 'absolute',
        top: '50%',
        left: '45%',
        transform: 'translate(-50%,-50%)',
        backgroundClip: 'text',
        '-webkit-background-clip': 'text',
        '-moz-background-clip': 'text',
    },
}));
