import { AlertType, IAlert, IAlertAction } from '../Components/AlertBar';
import { makeAutoObservable } from 'mobx';
import { isNotNullOrUndefined } from '../Helpers/utils';

export class AlertStore {
    private _lastId: number = 0;
    public alerts: AlertImpl[] = [];
    public constructor() {
        makeAutoObservable(this);
    }

    public show(message: string, type: AlertType, action?: IAlertAction, hideTimeout: number = 6000): IAlert {
        const impl = new AlertImpl(String(++this._lastId), message, type, action);
        this.alerts.push(impl);

        if (isNotNullOrUndefined(hideTimeout) && hideTimeout > 0) {
            setTimeout(() => this.close(impl), hideTimeout);
        }
        return impl;
    }

    public close(instance: IAlert | AlertImpl): void {
        if ('id' in instance) {
            this.alerts = this.alerts.filter((v) => v.id !== instance.id);
        }
    }
}

class AlertImpl implements IAlert {
    public id: string;
    public callback?: IAlertAction;
    public alertType: AlertType;
    public message: string;
    public createTime: Date;

    public constructor(id: string, message: string, type: AlertType, callback?: IAlertAction) {
        this.id = id;
        this.message = message;
        this.alertType = type;
        this.callback = callback;
        this.createTime = new Date();
    }
}
