import React from "react";
import { Typography, Paper } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";

export function CheckEmailPage() {
    return (
        <Paper>
            <div
                style={{
                    marginTop: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "10rem",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <MailIcon />
                </div>
                <Typography style={{ padding: "2rem" }}>
                    Для продолжения регистрации пройдите по ссылке, отправленной
                    на указанный вами почтовый ящик!
                </Typography>
            </div>
        </Paper>
    );
}
