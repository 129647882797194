import { Button, CardContent, Typography } from '@material-ui/core';
import React from 'react';

export interface IHeaderProps {
    title: string;
    description?: string;
    buttonTitle?: string;
    onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
    isSubmitButton?: boolean;
    isButtonDisabled?: boolean;
}

export enum HeaderButtonKind {
    PrimaryOutlined,
    NegateOutlined,
}

export const Header: React.FC<IHeaderProps> = (props) => {
    return (
        <>
            <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.title}
                    </Typography>
                    {!!props.description && (
                        <Typography variant="body2" color="textSecondary" component="p">
                            {props.description}
                        </Typography>
                    )}
                </div>
                {!!props.buttonTitle && (
                    <Button
                        disabled={props.isButtonDisabled}
                        variant="contained"
                        color="primary"
                        onClick={props.onButtonClick}
                        type={props.isSubmitButton ? 'submit' : 'button'}
                        style={{ flexShrink: 0 }}
                    >
                        {props.buttonTitle}
                    </Button>
                )}
            </CardContent>
        </>
    );
};
