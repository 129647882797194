import { useState, useEffect } from "react";
import { AlertType } from "../../Components/AlertBar";
import { alertStore, authApi, historyService } from '../../Services/serviceRegistration';
import { validateValue } from "../../Helpers/validationSupport";
import { ForgotPasswordViewModel } from '../../Api';

export class ForgotPasswordFormState {
    public email?: string;
    public emailError?: string;
}

export function useActions() {    
    const [state, setState] = useState(new ForgotPasswordFormState());
    const [isBusy, setIsBusy] = useState(false);

    const query = new URLSearchParams(historyService.location.search);
    const emailFromQuery = query.get("email");

    useEffect(() => {
        if (!!emailFromQuery) {
            setState(v => ({ ...v, email: emailFromQuery }));
        }
    }, []);

    const handleSubmit = async () => {
        setIsBusy(true);
        try {

            if (validateEmail()) {
                alertStore.show("Проверьте, правильно ли вы заполнили поле с данными почты!", AlertType.Error);
                return;
            }        
            const data: ForgotPasswordViewModel = { email: state.email! };
            const response = await authApi.forgotPassword(data);
            if (!!response) {
                switch (response.status) {
                    case 404:
                        alertStore.show("Пользователя с таким адресом электронной почты не найден!", AlertType.Error);
                        break;
                    case 400: 
                        alertStore.show("Что-то не так с запросом. Попробуйте запустить процедуру на странице профиля заново!", AlertType.Error);
                        break;
                    case 200:
                        alertStore.show("На вашу почту была отправлена ссылка, с помощью которой вы сможете изменить ваш текущий пароль!", AlertType.Success);
                        break;
                }
            }
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        const error = validateEmail();
        setState(v => ({ ...v, emailError: error }));
    }, [state.email]);
        
    const validateEmail = () => validateValue(state.email)
        .ensureNonEmpty()
        .matchRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Не похоже на почту :(")           
        .getLastError();

    return { emailFromQuery, formState: state, setFormState: setState, handleSubmit, isBusy };
}
