import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { AlertType } from '../../Components/AlertBar';
import { alertStore, authApi, historyService } from '../../Services/serviceRegistration';
import { goTo } from '../../Helpers/history';
import { ConfirmEmailViewModel } from '../../Api';
import { Routes } from '../../Components/Router/routes';

export function ConfirmEmailPage() {
    const runConfirmation = async () => {
        const query = new URLSearchParams(historyService.location.search);
        const requestId = query.get('id');
        const token = query.get('token');
        if (requestId === null || token === null) {
            alertStore.show(
                'Не удается подтвердить электронную почту! Попробуйте запросить сброс пароля еще раз!',
                AlertType.Error,
            );
            return;
        }
        const payload: ConfirmEmailViewModel = { id: requestId, token };
        const response = await authApi.confirmEmail(payload);

        if (!!response && response.status === 200) {
            goTo(Routes.Pages.Login);
            alertStore.show(
                'Проверка почты успешно завершена! Вы были перенаправлены на страницу входа.',
                AlertType.Success,
            );
        } else if (!!response && response.status === 400) {
            alertStore.show(
                'Не удается подтвердить электронную почту! Попробуйте использовать другой адрес!',
                AlertType.Error,
            );
        } else {
            alertStore.show('Проблема соединения с сервером! Попробуйте подключится позднее.', AlertType.Error);
        }
    };
    void runConfirmation();
    return (
        <Paper>
            <div
                style={{
                    marginTop: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '10rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <DoneAllIcon />
                </div>
                <Typography style={{ padding: '2rem' }}>Все почти готово ...</Typography>
            </div>
        </Paper>
    );
}
