import { makeAutoObservable, reaction } from 'mobx';
import { PlayerDto, ProjectApi, ProjectDto } from '../Api';
import { UserStore } from './UserStore';
import { isNotNullOrUndefined, isNullOrUndefined } from '../Helpers/utils';
import { IRatedProject } from '../Models/FpiGame/IRatedProject';

export class FpiGameStore {
    public projects: ProjectDto[] = [];
    private _projectApi: ProjectApi;
    private _userStore: UserStore;

    public constructor(projectApi: ProjectApi, userStore: UserStore) {
        this._projectApi = projectApi;
        this._userStore = userStore;
        makeAutoObservable(this);

        reaction(
            () => this._userStore.isUserProfileLoaded,
            async () => {
                await this.fetchProjects();
            },
        );
    }

    public get projectRating(): IRatedProject[] {
        return this.projects
            .map((p) => ({
                project: p,
                tokenNumber:
                    (p.records?.reduce((totalReward, contribution) => totalReward + (contribution.reward ?? 0), 0) ??
                        0) * 2,
            }))
            .sort((a, b) => {
                if (a.tokenNumber > b.tokenNumber) {
                    return -1;
                }
                if (a.tokenNumber < b.tokenNumber) {
                    return 1;
                }
                return 0;
            });
    }

    public getPlayerRate(targetPlayer: PlayerDto, projects: ProjectDto | ProjectDto[]): number {
        let result: number = 0;
        for (const project of Array.isArray(projects) ? projects : [projects]) {
            result +=
                project.records
                    ?.filter((record) => isNullOrUndefined(targetPlayer.id) || record.player?.id === targetPlayer.id)
                    .reduce((totalReward, contribution) => totalReward + (contribution.reward ?? 0), 0) ?? 0;
        }
        return result;
    }

    public async fetchProjects(): Promise<void> {
        this.projects = [];
        if (this._userStore.isUserProfileLoaded) {
            const response = await this._projectApi.getProjects();
            this.projects = response.data;
        }
    }

    public updateProjectInStore(replacement: ProjectDto) {
        this.projects = this.projects.map((project) =>
            isNotNullOrUndefined(replacement.id) && replacement.id === project.id ? replacement : project,
        );
    }
}
