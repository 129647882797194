import { Card, CardActionArea, CardContent, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { toRussian as toUserRoleRussian } from '../../Models/Profile/UserRole';
import { useActions } from './actions';
import { CardRow, CardRowType } from '../../Components/CardRow/CardRow';
import EmailSetupDialog from '../../Components/ConfirmEmailDialog';
import ChangeUsernameDialog from '../../Components/ChangeUsernameDialog';
import { goTo } from '../../Helpers/history';
import { Routes } from '../../Components/Router/routes';
import { observer } from 'mobx-react';
import { useStores } from '../../Services/serviceRegistration';

export const ProfilePage: React.FC = observer(() => {
    const { userStore } = useStores();
    const { state, setState, onSuccessEmailChangeModal, onSuccessUsernameChange, closeModals } = useActions();

    return !!state.userProfile ? (
        <>
            <Paper elevation={0} style={{ margin: '2rem' }}>
                <Card style={{ marginTop: '2rem' }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Профиль
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Здесь вы можете ознакомиться со своими данными или изменить их
                        </Typography>
                    </CardContent>
                    <CardActionArea
                        disabled={state.isBusy}
                        onClick={(_) =>
                            setState((s) => ({
                                ...s,
                                changeUsernameDialogVisibility: true,
                            }))
                        }
                    >
                        <CardRow
                            label="Никнейм"
                            title={state.userProfile.userName}
                            description="Изменить никнейм"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                    <CardActionArea
                        disabled={state.isBusy}
                        onClick={(_) =>
                            setState((s) => ({
                                ...s,
                                emailConfirmationDialogVisibility: true,
                            }))
                        }
                    >
                        {state.userProfile.emailConfirmed ? (
                            <CardRow
                                label="Почта"
                                title={state.userProfile.userEmail}
                                description="Сменить адрес почты"
                                type={CardRowType.Arrow}
                            />
                        ) : (
                            <CardRow
                                label="Почта"
                                title={state.userProfile.userEmail}
                                description="Подтвердите вашу почту"
                                type={CardRowType.Arrow}
                                descriptionColor={'error'}
                            />
                        )}
                    </CardActionArea>
                    <CardRow
                        label="ФИО"
                        title={`${state.userProfile.lastName} ${state.userProfile.firstName} ${state.userProfile.patronymic}`}
                    />
                    <CardActionArea onClick={() => goTo(Routes.Pages.EditExperience)}>
                        <CardRow
                            label="Опыт"
                            title={userStore.currentUserDto?.experience || 'Информация не указана'}
                            description="Нажмите, чтобы изменить"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                    <CardActionArea onClick={() => goTo(Routes.Pages.EditLuggage)}>
                        <CardRow
                            label="Багаж"
                            title={userStore.currentUserDto?.luggage || 'Информация не указана'}
                            description="Нажмите, чтобы изменить"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                    <CardActionArea onClick={() => goTo(Routes.Pages.EditEducation)}>
                        <CardRow
                            label="Образование"
                            title={
                                userStore.currentUserDto?.education && userStore.currentUserDto?.education.length > 0
                                    ? `Указано блоков с образованием: ${userStore.currentUserDto?.education.length}`
                                    : 'Информация не указана'
                            }
                            description="Нажмите, чтобы изменить"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                    <CardRow label="Телефон" title={state.userProfile.phoneNumber} />
                    <CardRow
                        label="Имя компании"
                        title={`${
                            !!state.userProfile.userCompany && !!state.userProfile.userCompany.name
                                ? state.userProfile.userCompany.name
                                : 'Не указано'
                        }`}
                    />
                    <CardRow label="Роль" title={toUserRoleRussian(state.userProfile.role)} />
                </Card>
                <Card style={{ marginTop: '2rem' }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Настройки безопасности
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Смена пароля, двухфакторная авторизация и другие параметры безопасности
                        </Typography>
                    </CardContent>
                    <CardActionArea disabled>
                        <CardRow
                            label="Пароль"
                            title="В разработке"
                            description="Изменить пароль"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                    <CardActionArea disabled>
                        <CardRow
                            label="2-факторная защита"
                            title="В разработке"
                            description="Настроить"
                            type={CardRowType.Arrow}
                        />
                    </CardActionArea>
                </Card>
            </Paper>
            <EmailSetupDialog
                isActualEmailVerified={state.userProfile.emailConfirmed}
                email={state.userProfile.userEmail}
                isVisible={state.emailConfirmationDialogVisibility}
                onSuccess={onSuccessEmailChangeModal}
                onCancel={closeModals}
            />
            <ChangeUsernameDialog
                username={state.userProfile.userName}
                isVisible={state.changeUsernameDialogVisibility}
                onSuccess={onSuccessUsernameChange}
                onCancel={closeModals}
            />
        </>
    ) : (
        <></>
    );
});
