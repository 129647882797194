import React from 'react';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { useStores } from '../../Services/serviceRegistration';
import { observer } from 'mobx-react';
import { isNotNullOrUndefined } from '../../Helpers/utils';

const Alert: React.FC<AlertProps> = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const AlertBar: React.FC = observer(() => {
    const { alertStore } = useStores();

    const closeAlert = (alert: IAlert) => {
        alertStore.close(alert);
    };

    return (
        <>
            {alertStore.alerts.map((alert, index) => (
                <Snackbar
                    key={alert.id}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    style={{ marginBottom: `${index * 3.5}rem` }}
                    open
                >
                    <Alert
                        onClose={() => closeAlert(alert)}
                        severity={alert.alertType.toString() as Color}
                        action={
                            isNotNullOrUndefined(alert.callback) && (
                                <Button
                                    variant={'outlined'}
                                    children={alert.callback.label}
                                    onClick={() => alert?.callback?.callback()}
                                />
                            )
                        }
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
});

export enum AlertType {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export interface IAlert {
    alertType: AlertType;
    message: string;
    callback?: IAlertAction;
}

export interface IAlertAction {
    label: string;
    callback: () => void;
}
