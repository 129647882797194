import { createBrowserHistory, History } from 'history';
import { Events } from '../Helpers/events';
import { hashIt } from '../Helpers/utils';

export interface IHistoryService extends History {
    goTo(url: string, query?: string): void;
    goToExternal(url: string): void;
}

export const createHistoryService = (): IHistoryService => {
    const history = createBrowserHistory();
    return {
        ...history,
        goTo: (url, query) => {
            Events.OnUrlStateChanged.invoke(url, query);
            history.push({ pathname: url, search: query, state: hashIt(url + query) });
        },
        goToExternal: (url) => {
            window.location.href = url;
        },
    };
};
