import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiTextField-root": {
                margin: theme.spacing(1),
                width: '270px'
            },
            margin: 'auto',
            padding: theme.spacing(2),
            width: '320px',
        },
        header: {
            margin: theme.spacing(2, 2, 4, 2),
            wordWrap: "normal",
            textAlign: "center"
        },
        restoreLink: {
            textAlign: "center",
            margin: theme.spacing(2)
            
        },
        submitButton: {
            margin: 'auto',
            marginBottom: theme.spacing(1)
        }
    })
);
