import { ValidationProblemDetails } from '../../Api';

export class BadResponse {
    public type: string;
    public title: string;
    public status: number;
    public traceId: string;
    public errors: Map<string, string[]>;
    public firstError: string | undefined;

    public constructor(instance: ValidationProblemDetails) {
        this.type = instance.type || '';
        this.title = instance.title || '';
        this.status = instance.status || -1;
        this.traceId = instance.traceId;
        this.errors = new Map();
        let isFirstEntry = true;
        for (const [key, value] of Object.entries(instance.errors || [])) {
            if (isFirstEntry) {
                isFirstEntry = false;
                this.firstError = value[0];
            }
            this.errors.set(key, value);
        }
    }
}