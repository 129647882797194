export namespace Routes {
    export namespace Pages {
        export const Root = '/';
        export const Login = '/login';
        export const Register = '/register';
        export const CheckEmail = '/check-email';
        export const ConfirmEmail = '/confirm-email';
        export const ChangeEmail = '/change-email';
        export const ForgotPassword = '/forgot-password';
        export const ChangePassword = '/reset-password';
        export const Profile = '/user-profile';
        export const EditExperience = Pages.Profile + '/experience/edit';
        export const EditLuggage = Pages.Profile + '/luggage/edit';
        export const EditEducation = Pages.Profile + '/education/edit';
        export const FpiGame_PlayerDashboard = '/fpi/dashboard';
        export const FpiGame_ProjectManagement = '/fpi/admin/projects';
        export const FpiGame_ProjectManagement_ModifyRewards = '/fpi/admin/projects/edit/rewards';
        export const FpiGame_PlayerManagement = '/fpi/admin/players';
        export const FpiGame_Rating = '/fpi/admin/rating';
    }
}
