import React from "react";
import { FormControl, CardActions, Button, CardContent, Card, TextField, Typography, Box, Link, LinearProgress } from "@material-ui/core";
import { useStyles } from "../LoginPage/styles";
import { useActions } from "./actions";

export default function ChangePasswordPage() {
    const classes = useStyles();
    const { query, formState, setFormState, handleSubmit, isBusy } = useActions();

    return (
        <div className={classes.root}>
            <Card raised>
                <LinearProgress hidden={!isBusy} />
                <CardContent>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Новый пароль 
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            Укажите новый пароль аккаунта {!!query.email ? (<b>{query.email}</b>) : ({})}, 
                            который вы будете использовать для дальнейшего входа в систему.
                        </Typography>
                    </CardContent>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="password-field"
                            label="Пароль"
                            variant="outlined"
                            type="password"
                            error={!!formState.passwordError}
                            helperText={formState.passwordError}
                            onChange={e => setFormState({ ...formState, password: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="password-repeat-field"
                            label="Повторите пароль"
                            variant="outlined"
                            type="password"
                            error={!!formState.passwordRepeatError}
                            helperText={formState.passwordRepeatError}
                            onChange={e => setFormState({ ...formState, passwordRepeat: e.target.value })}
                        />
                    </FormControl>
                </CardContent>
                <CardActions>
                    <Button 
                        onClick={handleSubmit}
                        className={classes.submitButton}
                        disabled={isBusy}
                        variant="contained"
                        color="primary"
                    >
                        Сменить пароль
                    </Button>
                </CardActions>
            </Card>
            <Typography component="div" className={classes.restoreLink}>
                <Box fontSize={"0.8rem"}>
                    <Link href="https://akmetron.ru/">АО Акметрон</Link>
                </Box>
            </Typography>
        </div>
    );
}