import { FpiRole } from '../../Api';
import { isNullOrUndefined } from '../../Helpers/utils';

export const AllProjectRoles: FpiRole[] = [
    FpiRole.Engineer,
    FpiRole.Expert,
    FpiRole.FactorySupervisor,
    FpiRole.ProductSpecialist,
    FpiRole.Ideologist,
];

export const projectRoleToRussian = (role: FpiRole | null | undefined) => {
    if (isNullOrUndefined(role)) {
        return '-';
    }
    switch (role) {
        case FpiRole.Ideologist:
            return 'Идеолог';
        case FpiRole.FactorySupervisor:
            return 'Куратор фабрик';
        case FpiRole.ProductSpecialist:
            return 'Продуктолог';
        case FpiRole.Engineer:
            return 'Инженер';
        case FpiRole.Expert:
            return 'Эксперт';
        default:
            return FpiRole[role];
    }
};
