import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardContent, Container, TextField } from '@material-ui/core';
import { Header } from '../../Components/Header/Header';
import { useStores } from '../../Services/serviceRegistration';

interface IExperiencePageState {
    isBusy: boolean;
    hasChanges: boolean;
    experience: string;
}

export const ExperiencePage: React.FC = observer(() => {
    const { userStore } = useStores();
    const [state, setState] = useState<IExperiencePageState>({ isBusy: false, hasChanges: false, experience: '' });

    useEffect(() => {
        setState((s) => ({ ...s, experience: userStore.currentUserDto?.experience ?? '' }));
    }, [userStore.currentUserDto]);

    return (
        <Container style={{ marginTop: '1rem' }}>
            <Card>
                <Header
                    title={'Редактирование опыта'}
                    description={'Заполните здесь информацию о вашем опыте'}
                    buttonTitle={'Сохранить'}
                    isButtonDisabled={state.isBusy || !state.hasChanges}
                    onButtonClick={() => {
                        setState((s) => ({ ...s, isBusy: true }));
                        try {
                            void userStore.updateUserExperience(state.experience);
                        } finally {
                            setState((s) => ({ ...s, isBusy: false, hasChanges: false }));
                        }
                    }}
                />
                <CardContent>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        id="user-experience"
                        label="Опыт"
                        multiline
                        rows={12}
                        value={state.experience}
                        onChange={(e) => {
                            setState((s) => ({ ...s, hasChanges: true, experience: e.target.value }));
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    );
});
