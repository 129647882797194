import { AlertStore } from './alertStore';
import { reaction } from 'mobx';
import { UserStore } from '../Stores/UserStore';
import { AlertType, IAlert } from '../Components/AlertBar';
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from '../Helpers/utils';
import { goTo } from '../Helpers/history';
import { Routes } from '../Components/Router/routes';

export class SuggestionService {
    private _alertStore: AlertStore;
    private _userStore: UserStore;
    private _fillUpBioAlertInstance?: IAlert = undefined;

    public constructor(alertStore: AlertStore, userStore: UserStore) {
        this._alertStore = alertStore;
        this._userStore = userStore;

        reaction(
            () => this._userStore.currentUserDto,
            () => {
                this.hideFillUpBioAlert();
                if (
                    this._userStore.currentUserDto !== undefined &&
                    isEmptyString(this._userStore.currentUserDto.experience) &&
                    isEmptyString(this._userStore.currentUserDto.luggage) &&
                    (isNullOrUndefined(this._userStore.currentUserDto.education) ||
                        this._userStore.currentUserDto.education.length === 0)
                ) {
                    this._fillUpBioAlertInstance = this._alertStore.show(
                        'Заполните дополнительную информацию в профиле!',
                        AlertType.Info,
                        {
                            label: 'Заполнить',
                            callback: () => {
                                this.hideFillUpBioAlert();
                                goTo(Routes.Pages.Profile);
                            },
                        },
                        0,
                    );
                }
            },
        );
    }

    private hideFillUpBioAlert() {
        if (isNotNullOrUndefined(this._fillUpBioAlertInstance)) {
            this._alertStore.close(this._fillUpBioAlertInstance);
            this._fillUpBioAlertInstance = undefined;
        }
    }
}
