import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Card, CardActionArea, CardContent, TextField } from '@material-ui/core';
import { Header } from '../../Components/Header/Header';
import { useStores } from '../../Services/serviceRegistration';
import { isNullOrUndefined } from '../../Helpers/utils';
import { runInAction } from 'mobx';
import { CardRow, CardRowType } from '../../Components/CardRow/CardRow';
import { goTo } from '../../Helpers/history';
import { Routes } from '../../Components/Router/routes';

export const ProjectEditor: React.FC = observer(() => {
    const { gameAdminStore } = useStores();

    const selectedProject = useMemo(() => {
        return gameAdminStore.selectedProject;
    }, [gameAdminStore.selectedProject]);

    return (
        <div style={{ padding: '1rem' }}>
            <Card>
                {isNullOrUndefined(gameAdminStore.selectedProject?.id) ? (
                    <Header
                        title={'Создание нового проекта'}
                        buttonTitle={'Сохранить'}
                        onButtonClick={() => {
                            if (isNullOrUndefined(gameAdminStore.selectedProject)) {
                                return;
                            }
                            void gameAdminStore.publishNewProject(gameAdminStore.selectedProject);
                        }}
                        isButtonDisabled={gameAdminStore.isBusy}
                    />
                ) : (
                    <Header
                        title={'Редактирование проекта'}
                        buttonTitle={'Сохранить'}
                        isButtonDisabled={
                            gameAdminStore.isBusy || gameAdminStore.isSaved(gameAdminStore.selectedProject?.id)
                        }
                        onButtonClick={() => {
                            if (isNullOrUndefined(gameAdminStore.selectedProject)) {
                                return;
                            }
                            void gameAdminStore.updateProject(gameAdminStore.selectedProject);
                        }}
                    />
                )}
                <CardContent>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        required
                        id="project-title"
                        label="Имя проекта"
                        value={selectedProject?.title}
                        onChange={(e) => {
                            runInAction(() => (selectedProject!.title = e.currentTarget.value));
                            gameAdminStore.setSaveStatus(gameAdminStore.selectedProject?.id, false);
                        }}
                    />
                </CardContent>
                <CardContent>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        id="project-description"
                        label="Описание проекта"
                        multiline
                        rows={4}
                        value={selectedProject?.description}
                        onChange={(e) => {
                            runInAction(() => (selectedProject!.description = e.currentTarget.value));
                            gameAdminStore.setSaveStatus(gameAdminStore.selectedProject?.id, false);
                        }}
                    />
                </CardContent>
                <CardActionArea onClick={() => goTo(Routes.Pages.FpiGame_ProjectManagement_ModifyRewards, `projectId=${selectedProject?.id}`)}>
                    <CardRow
                        title={"Применить модификатор ко всем вкладам"}
                        type={CardRowType.Arrow}
                    />
                </CardActionArea>
            </Card>
        </div>
    );
});
