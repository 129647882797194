import React, { useCallback } from 'react';
import TwoCoins from '../../Img/TwoCoins.svg';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface ICoinProps {
    totalRate: number;
}

export const Coin: React.FC<ICoinProps> = (props) => {
    const totalRateToString = useCallback((value: number) => {
        if (value === 0) {
            return '0';
        }
        if (value < 0) {
            return 'Err';
        }
        if (value < 1e1) {
            return value.toFixed(1);
        }
        if (value < 1e2) {
            return value.toFixed(0);
        }
        if (value < 1e4) {
            return String(value / 1e3).slice(0, 3) + 'K';
        }
        if (value < 1e5) {
            return String(value / 1e3).slice(0, 3) + 'K';
        }
        if (value < 1e7) {
            return String(value / 1e6).slice(0, 3) + 'M';
        }
        if (value < 1e8) {
            return String(value / 1e6).slice(0, 3) + 'M';
        }
        return String(Infinity).slice(0, 3);
    }, []);

    const classes = makeStyles(() => ({
        coinText: {
            backgroundColor: '#c07d00',
            color: 'transparent',
            textShadow: '0.5px 0.5px 0.5px #fecb00',
            fontWeight: 'bold',
            position: 'absolute',
            top: '50%',
            left: '45%',
            transform: 'translate(-50%,-50%)',
            backgroundClip: 'text',
            '-webkit-background-clip': 'text',
            '-moz-background-clip': 'text',
        },
    }))();
    const value = totalRateToString(props.totalRate);

    return (
        <div style={{ position: 'relative', textAlign: 'center' }}>
            <img src={TwoCoins} alt={`Кол-во токенов: ${value}`} style={{ width: '3em', padding: '0.5em' }} />
            <Typography className={classes.coinText}>{value}</Typography>
        </div>
    );
};
