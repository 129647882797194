import { LoginFormState } from '.';
import { validateAll, validateValue } from '../../Helpers/validationSupport';
import { AlertType } from '../../Components/AlertBar';
import { alertStore, authApi } from '../../Services/serviceRegistration';
import { useState, useEffect } from 'react';
import { authService } from '../../Services/serviceRegistration';
import { goTo } from '../../Helpers/history';
import { Routes } from '../../Components/Router/routes';
import { LoginViewModel } from '../../Api';

export function useActions() {
    const [formState, setFormState] = useState<LoginFormState>(new LoginFormState());
    const [firstTimeRun, setFirstTimeRunState] = useState(true);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        const checkUserToken = async () => {
            const user = await authService.fetchUserProfile();
            if (!!user) {
                goTo(Routes.Pages.FpiGame_PlayerDashboard);
            }
        };
        void checkUserToken();
    }, []);

    useEffect(() => {
        setFirstTimeRunState(false);
    }, [firstTimeRun]);

    useEffect(() => {
        setFormState((v) => ({ ...v, emailError: validateEmail() }));
    }, [formState.email]);

    useEffect(() => {
        setFormState((v) => ({ ...v, passwordError: validatePassword() }));
    }, [formState.password]);

    const handleSubmit = async () => {
        setIsBusy(true);
        try {
            if (validateAll(validateEmail(), validatePassword())) {
                alertStore.show('Не все поля заполнены верно!', AlertType.Error);
                return;
            }
            const data: LoginViewModel = {
                email: formState.email || '',
                password: formState.password || '',
            };
            const response = await authApi.login(data);
            if (!!response) {
                switch (response.status) {
                    case 200:
                        const jwt_token = response.data.token || '';
                        await authService.setToken(jwt_token);
                        await authService.fetchUserProfile(true);
                        alertStore.show(
                            'Вход был произведен успешно! Открываю главную страницу...',
                            AlertType.Success,
                        );
                        goTo(Routes.Pages.FpiGame_PlayerDashboard);
                        break;
                }
            }
        } finally {
            setIsBusy(false);
        }
    };

    const validateEmail = () =>
        validateValue(formState.email)
            .skipIf(firstTimeRun)
            .ensureNonEmpty()
            .matchRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Не похоже на почту :(')
            .getLastError();

    const validatePassword = () =>
        validateValue(formState.password).skipIf(firstTimeRun).ensureNonEmpty().getLastError();

    return { formState, setFormState, handleSubmit, isBusy };
}
