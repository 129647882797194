import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useActions, IChangeUsernameDialogProps } from "./actions";
import { TextInputEvent } from '../../StaticTypes';

export default function ChangeUsernameDialog(
    props: IChangeUsernameDialogProps
) {
    const { isBusy, state, setState, handleClose, handleSuccess } = useActions(
        props
    );

    const updateUsername = (e: TextInputEvent) => {
        e.persist();
        setState(v => ({
            ...v,
            username: e.target.value
        }));
    };

    return (
        <Dialog
            onSubmit={handleSuccess}
            open={state.isVisible}
            disableBackdropClick={isBusy}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Сменить никнейм</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Укажите новый никнейм для входа в личный кабинет.
                </DialogContentText>
                <TextField
                    autoFocus
                    disabled={isBusy}
                    margin="dense"
                    id="name"
                    label="Никнейм"
                    type="username"
                    fullWidth
                    error={!!state.usernameError}
                    helperText={state.usernameError}
                    defaultValue={state.username}
                    onChange={updateUsername}
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={isBusy} onClick={handleClose} color="primary">
                    Отмена
                </Button>
                <Button
                    type="submit"
                    disabled={isBusy}
                    onClick={handleSuccess}
                    color="primary"
                >
                    Сменить
                </Button>
            </DialogActions>
        </Dialog>
    );
}
