import React from "react";
import { FormControl, CardActions, Button, CardContent, Card, TextField, Typography, Box, Link, LinearProgress } from "@material-ui/core";
import { useStyles } from "./styles";
import { useActions } from "./actions";
import { Routes } from '../../Components/Router/routes';

export default function RegisterPage() {
    const classes = useStyles();
    const { formState, setFormState, handleSubmit, isBusy } = useActions();

    return (
        <div className={classes.root}>
            <Card raised>
                <LinearProgress hidden={!isBusy} />
                <CardContent>
                    <Typography variant="h5" className={classes.header}>Регистрация</Typography>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="username-field"
                            label="Никнейм *"
                            variant="outlined"
                            placeholder="nickname123"
                            error={!!formState.usernameError}
                            helperText={formState.usernameError}
                            onChange={e => setFormState({ ...formState, username: e.target.value })}
                            onBlur={e => setFormState({ ...formState, username: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="email-field"
                            label="Электронная почта *"
                            variant="outlined"
                            placeholder="your.email@mailservice.ru"
                            error={!!formState.emailError}
                            helperText={formState.emailError}
                            onChange={e => setFormState({ ...formState, email: e.target.value })}
                            onBlur={e => setFormState({ ...formState, email: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="lastName-field"
                            label="Фамилия *"
                            variant="outlined"
                            placeholder="Иванов"
                            error={!!formState.lastNameError}
                            helperText={formState.lastNameError}
                            onChange={e => setFormState({ ...formState, lastName: e.target.value })}
                            onBlur={e => setFormState({ ...formState, lastName: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="firstName-field"
                            label="Имя *"
                            variant="outlined"
                            placeholder="Иван"
                            error={!!formState.firstNameError}
                            helperText={formState.firstNameError}
                            onChange={e => setFormState({ ...formState, firstName: e.target.value })}
                            onBlur={e => setFormState({ ...formState, firstName: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="patronymic-field"
                            label="Отчество"
                            variant="outlined"
                            placeholder="Иванович"
                            onChange={e => setFormState({ ...formState, patronymic: e.target.value })}
                            onBlur={e => setFormState({ ...formState, patronymic: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="companyName-field"
                            label="Имя компании *"
                            variant="outlined"
                            placeholder="ООО Иванов И.И."
                            error={!!formState.companyNameError}
                            helperText={formState.companyNameError}
                            onChange={e => setFormState({ ...formState, companyName: e.target.value })}
                            onBlur={e => setFormState({ ...formState, companyName: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="phoneNumber-field"
                            label="Номер телефона *"
                            variant="outlined"
                            type="phone"
                            placeholder="+79991112233"
                            error={!!formState.phoneNumberError}
                            helperText={formState.phoneNumberError}
                            onChange={e => setFormState({ ...formState, phoneNumber: e.target.value })}
                            onBlur={e => setFormState({ ...formState, phoneNumber: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="password-field"
                            label="Пароль *"
                            variant="outlined"
                            type="password"
                            error={!!formState.passwordError}
                            helperText={formState.passwordError}
                            onChange={e => setFormState({ ...formState, password: e.target.value })}
                            onBlur={e => setFormState({ ...formState, password: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            disabled={isBusy}
                            id="password-repeat-field"
                            label="Повторите пароль *"
                            variant="outlined"
                            type="password"
                            error={!!formState.passwordRepeatError}
                            helperText={formState.passwordRepeatError}
                            onChange={e => setFormState({ ...formState, passwordRepeat: e.target.value })}
                            onBlur={e => setFormState({ ...formState, passwordRepeat: e.target.value })}
                        />
                    </FormControl>
                </CardContent>
                <CardActions>
                    <Button 
                        onClick={handleSubmit}
                        className={classes.submitButton}
                        disabled={isBusy}
                        variant="contained"
                        color="primary"
                    >
                        Зарегистрироваться
                    </Button>
                </CardActions>
            </Card>
            <Typography component="div" className={classes.restoreLink}>
                <Box fontSize={"0.8rem"}>
                    <Link href="https://akmetron.ru/">АО Акметрон</Link>
                    {" | "}
                    <Link href={Routes.Pages.ForgotPassword}>Восстановить пароль</Link>
                </Box>
            </Typography>
        </div>
    );
}

export class RegisterFormState {
    public username?: string;
    public email?: string;
    public firstName?: string;
    public lastName?: string;
    public patronymic?: string;  
    public phoneNumber?: string;  
    public companyName?: string;
    public password?: string;
    public passwordRepeat?: string;
    
    public usernameError?: string;
    public emailError?: string;
    public firstNameError?: string;
    public phoneNumberError?: string;
    public lastNameError?: string;
    public companyNameError?: string;
    public passwordError?: string;
    public passwordRepeatError?: string;

}