import { Company } from './Company';
import { UserDto, UserRole } from '../../Api';

export class User {
    public id: string;
    public userName: string;
    public userEmail: string;
    public role: UserRole;
    public emailConfirmed: boolean;
    public isTwoFactorEnabled: boolean;
    public isArchived: boolean;
    public firstName: string;
    public lastName: string;
    public patronymic: string;
    public userCompany?: Company;
    public phoneNumber: string;
    public token: string | undefined;

    public constructor(instance: UserDto) {
        this.id = instance.id || '';
        this.userName = instance.userName || '';
        this.userEmail = instance.userEmail || '';
        this.role = instance.role || UserRole.Unauthorized;
        this.emailConfirmed = instance.emailConfirmed || false;
        this.isTwoFactorEnabled = instance.isTwoFactorEnabled || false;
        this.isArchived = instance.isArchived || false;
        this.firstName = instance.firstName || '';
        this.lastName = instance.lastName || '';
        this.patronymic = instance.patronymic || '';
        this.userCompany = !!instance.userCompany ? new Company(instance.userCompany) : undefined;
        this.phoneNumber = instance.phoneNumber || '';
        this.token = !!instance.token ? instance.token : undefined;
    }
}
