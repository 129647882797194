import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@material-ui/core';
import { LoginButton } from '../Buttons/LoginButton';
import { RegisterButton } from '../Buttons/RegisterButton';
import { useLayoutStyles } from '../MainLayout/styles';
import { Routes } from '../Router/routes';

export default function SignLayout(props: any) {
    const classes = useLayoutStyles();

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.title}>
                        <Typography
                            className={classes.titleTypography}
                            variant="h6"
                            component="a"
                            href={Routes.Pages.Root}
                        >
                            Фабрика перспективных идей
                        </Typography>
                    </div>
                    <LoginButton />
                    <RegisterButton />
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm">{props.children}</Container>
        </div>
    );
}
