import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useGameManagementStyles = makeStyles((theme: Theme) =>
    createStyles({
        leftColumn: {
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 15rem)',
            },
            overflowY: 'auto',
        },
        reducedLeftColumn: {
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 20rem)',
            },
            overflowY: 'auto',
        },
        rightColumn: {
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 12.5rem)',
            },
        },
        userManagementRightColumn: {
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 9.5rem)',
                overflowY: 'auto',
            },
        },
        emptyRightColumn: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '50vh',
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 9.5rem)',
            },
        },
        inheritHeight: {
            height: 'inherit',
        },
        listRoot: {
            overflowY: 'auto',
            height: 'inherit',
            padding: 0,
        },
        listSubheader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
        },
        button: {
            minWidth: '0',
            padding: '3px',
            '& .MuiButton-label': {
                padding: '0',
            },
            '& .MuiButton-startIcon': {
                margin: '0',
            },
        },
    }),
);
