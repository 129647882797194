import { useState, useEffect } from "react";
import { validateValue } from "../../Helpers/validationSupport";

export interface IEmailSetupDialogProps {
    email: string;
    onSuccess?: (email: string) => Promise<boolean>;
    onCancel?: () => Promise<boolean>;
    isVisible: boolean;
    isActualEmailVerified: boolean;
    emailError?: string;
}

export function useActions(props: IEmailSetupDialogProps) {
    const [state, setState] = useState<IEmailSetupDialogProps>(props);
    const [isBusy, setIsBusy] = useState(false);

    const handleClose = async () => {
        setIsBusy(true);
        try {
            const result = await state.onCancel?.call(state);
            setState(v => ({ ...v, isVisible: !!result }));
        } finally {
            setIsBusy(false);
        }
    };
    
    const validateEmail = () => validateValue(state.email)
        .ensureNonEmpty()
        .matchRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Не похоже на почту :(")           
        .getLastError();

    const handleSuccess = async () => {
        if (!!validateEmail()) {
            return;
        }
        setIsBusy(true);
        try {
            const result = await state.onSuccess?.call(
                state,
                state.email
            );
            setState(v => ({ ...v, isVisible: !!result }));
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {        
        setState(v => ({ ...v, emailError: validateEmail() }));

    }, [state.email]);

    useEffect(() => {
        setState(props);
    }, [props]);

    return { isBusy, state, setState, handleClose, handleSuccess };
}
