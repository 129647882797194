import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { PermissionSupport } from '../../Components/PermissionSupport';
import { UserRole } from '../../Api';
import {
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { useStores } from '../../Services/serviceRegistration';
import { PlayerEditor } from './PlayerEditor';
import { runInAction } from 'mobx';
import { ProjectEditor } from './ProjectEditor';
import { isNotNullOrUndefined } from '../../Helpers/utils';
import { ContributionEditor } from './ContributionEditor';
import { useGameManagementStyles } from './GameManagementStyles';
import { Dropdown } from '../../Components/Dropdown/Dropdown';
import { AttachmentEditor } from './AttachmentEditor';

export const ProjectManagementPage: React.FC = observer(() => {
    const { fpiGameStore, gameAdminStore } = useStores();
    useEffect(() => {
        gameAdminStore.switchPage(gameAdminStore.pageList[0]);
    }, []);
    const classes = useGameManagementStyles();
    return (
        <Container>
            <PermissionSupport allow={[UserRole.Admin]}>
                <Grid container spacing={2} style={{ marginTop: '1rem' }} alignContent={'stretch'}>
                    <Grid item md={4} xs={12}>
                        <Card>
                            <CardContent
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Dropdown
                                    render={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    renderOption={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto 0' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    options={gameAdminStore.pageList}
                                    value={gameAdminStore.selectedPage}
                                    onChange={(option) => {
                                        gameAdminStore.switchPage(option);
                                    }}
                                />
                                <Button
                                    disabled={gameAdminStore.isBusy}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => gameAdminStore.createNewProject()}
                                >
                                    Создать
                                </Button>
                            </CardContent>
                            <List className={classes.leftColumn}>
                                {fpiGameStore.projects.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="Нету доступных проектов"
                                            secondary="Создайте проект кнопкой в заголовке"
                                        />
                                    </ListItem>
                                )}
                                {fpiGameStore.projects.map((p) => (
                                    <ListItem
                                        button
                                        key={p.id}
                                        onClick={() => {
                                            runInAction(() => (gameAdminStore.selectedProject = p));
                                        }}
                                        selected={
                                            !!gameAdminStore.selectedProject &&
                                            gameAdminStore.selectedProject.id === p.id
                                        }
                                    >
                                        <ListItemText primary={p.title} secondary={p.description} />
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Card>
                            {!gameAdminStore.selectedProject ? (
                                <Card className={classes.emptyRightColumn}>
                                    <Typography variant={'h5'}>Выберите проект</Typography>
                                </Card>
                            ) : isNotNullOrUndefined(gameAdminStore.selectedProject.id) ? (
                                <>
                                    <Tabs
                                        value={gameAdminStore.selectedTab}
                                        centered
                                        onChange={(e, v) => {
                                            runInAction(() => (gameAdminStore.selectedTab = v));
                                        }}
                                    >
                                        <Tab label="Проект" />
                                        <Tab label="Участники" />
                                        <Tab label="Вклад" />
                                        <Tab label="Доп. информация" />
                                    </Tabs>
                                    <div className={classes.rightColumn}>
                                        {gameAdminStore.selectedTab === 0 && <ProjectEditor />}
                                        {gameAdminStore.selectedTab === 1 && <PlayerEditor />}
                                        {gameAdminStore.selectedTab === 2 && <ContributionEditor />}
                                        {gameAdminStore.selectedTab === 3 && <AttachmentEditor />}
                                    </div>
                                </>
                            ) : (
                                <ProjectEditor />
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </PermissionSupport>
        </Container>
    );
});
