import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { historyService } from '../../Services/serviceRegistration';
import { Routes } from './routes';
import LoginPage from '../../Pages/LoginPage';
import RegisterPage from '../../Pages/RegisterPage';
import SignLayout from '../SignLayout';
import { CheckEmailPage } from '../../Pages/CheckEmailPage';
import { ConfirmEmailPage } from '../../Pages/ConfirmEmailPage';
import ChangeEmailPage from '../../Pages/ChangeEmailPage';
import ForgotPasswordPage from '../../Pages/ForgotPasswordPage';
import ChangePasswordPage from '../../Pages/ChangePasswordPage';
import { ProfilePage } from '../../Pages/ProfilePage';
import { MainLayout } from '../MainLayout';
import { PlayerDashboardPage } from '../../Pages/PlayerDashboard/PlayerDashboardPage';
import { NotFound } from '../../Pages/ErrorPages/NotFound';
import { ProjectManagementPage } from '../../Pages/GameManagement/ProjectManagementPage';
import { ExperiencePage } from '../../Pages/ProfilePage/ExperiencePage';
import { LuggagePage } from '../../Pages/ProfilePage/LuggagePage';
import { EducationPage } from '../../Pages/ProfilePage/EducationPage';
import { PlayerManagementPage } from '../../Pages/GameManagement/PlayerManagementPage';
import { RatingPage } from '../../Pages/GameManagement/RatingPage';
import { ModifyRewardsPage } from '../../Pages/GameManagement/ModifyRewardsPage';

export default function AppRouter() {
    return (
        <Router history={historyService}>
            <Switch>
                <Route path={Routes.Pages.Login} exact children={<SignLayout><LoginPage /></SignLayout>} />
                <Route path={Routes.Pages.Register} exact children={<SignLayout><RegisterPage /></SignLayout>} />
                <Route path={Routes.Pages.CheckEmail} exact children={<SignLayout><CheckEmailPage /></SignLayout>} />
                <Route path={Routes.Pages.ConfirmEmail} children={<SignLayout><ConfirmEmailPage /></SignLayout>} />
                <Route path={Routes.Pages.ChangeEmail} children={<SignLayout><ChangeEmailPage /></SignLayout>} />
                <Route path={Routes.Pages.ForgotPassword} exact children={<SignLayout><ForgotPasswordPage /></SignLayout>} />
                <Route path={Routes.Pages.ChangePassword} children={<SignLayout><ChangePasswordPage /></SignLayout>} />
                <Route path={Routes.Pages.EditExperience} exact children={<MainLayout><ExperiencePage /></MainLayout>} />
                <Route path={Routes.Pages.EditLuggage} exact children={<MainLayout><LuggagePage /></MainLayout>} />
                <Route path={Routes.Pages.EditEducation} exact children={<MainLayout><EducationPage /></MainLayout>} />
                <Route path={Routes.Pages.EditExperience} exact children={<MainLayout><ExperiencePage /></MainLayout>} />
                <Route path={Routes.Pages.Profile} children={<MainLayout><ProfilePage /></MainLayout>} />
                <Route path={Routes.Pages.FpiGame_ProjectManagement_ModifyRewards} children={ <MainLayout><ModifyRewardsPage /></MainLayout> } />
                <Route path={Routes.Pages.FpiGame_PlayerDashboard} exact children={ <MainLayout><PlayerDashboardPage /></MainLayout> } />
                <Route path={Routes.Pages.FpiGame_PlayerManagement} exact children={ <MainLayout><PlayerManagementPage /></MainLayout> } />
                <Route path={Routes.Pages.FpiGame_ProjectManagement} exact children={ <MainLayout><ProjectManagementPage /></MainLayout> } />
                <Route path={Routes.Pages.FpiGame_Rating} exact children={ <MainLayout><RatingPage /></MainLayout> } />
                <Route path={Routes.Pages.Root} exact children={ <MainLayout><PlayerDashboardPage /></MainLayout> } />
                <Route children={<MainLayout><NotFound /></MainLayout>}/>
            </Switch>
        </Router>
    );
}
