import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../Services/serviceRegistration';
import { useGameManagementStyles } from './GameManagementStyles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
} from '@material-ui/core';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import AddIcon from '@material-ui/icons/Add';
import { runInAction } from 'mobx';
import { Header } from '../../Components/Header/Header';
import DeleteIcon from '@material-ui/icons/Delete';

export const AttachmentEditor: React.FC = observer(() => {
    const { gameAdminStore } = useStores();

    const selectedAttachment = useMemo(() => {
        return gameAdminStore.selectedAttachment;
    }, [gameAdminStore.selectedAttachment]);

    const classes = useGameManagementStyles();
    return (
        <Grid container className={classes.inheritHeight}>
            <Grid item md={4} xs={12} className={classes.inheritHeight}>
                <List
                    className={classes.listRoot}
                    subheader={
                        <ListSubheader component="div" className={classes.listSubheader}>
                            Список доп. информации:
                            <Button
                                className={classes.button}
                                size={'small'}
                                onClick={() => {
                                    gameAdminStore.createNewAttachment();
                                }}
                                disabled={
                                    isNotNullOrUndefined(gameAdminStore.selectedAttachment) &&
                                    isNullOrUndefined(gameAdminStore.selectedAttachment.id)
                                }
                                startIcon={<AddIcon fontSize={'small'} />}
                                title={'Добавить доп. инфо'}
                                variant={'outlined'}
                            />
                        </ListSubheader>
                    }
                >
                    {gameAdminStore.selectedProject?.attachments?.length === 0 && (
                        <ListItem button>
                            <ListItemText
                                primary="В проекте нет доп. информации"
                                secondary="Создайте доп. информацию при помощи кнопки"
                            />
                        </ListItem>
                    )}
                    {gameAdminStore.selectedProject?.attachments?.map((s) => (
                        <ListItem
                            button
                            selected={s.id === gameAdminStore.selectedAttachment?.id}
                            key={s.id}
                            onClick={() => {
                                runInAction(() => (gameAdminStore.selectedAttachment = s));
                            }}
                        >
                            <ListItemText primary={s.description} secondary={s.url} />
                            <IconButton
                                disabled={gameAdminStore.isBusy}
                                edge="end"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isNullOrUndefined(gameAdminStore.selectedProject)) {
                                        return;
                                    }
                                    void gameAdminStore.removeAttachment(gameAdminStore.selectedProject, s);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item md={8} xs={12}>
                {gameAdminStore.selectedAttachment === undefined ? (
                    <Typography variant={'h5'} style={{ textAlign: 'center', marginTop: `2.5rem` }}>
                        Выберите элемент из списка
                    </Typography>
                ) : (
                    <Card style={{ margin: '1rem' }}>
                        {isNullOrUndefined(gameAdminStore.selectedAttachment.id) && (
                            <Header
                                title={'Создание новой доп. информации'}
                                buttonTitle={'Сохранить'}
                                isButtonDisabled={gameAdminStore.isBusy}
                                onButtonClick={() => {
                                    void gameAdminStore.publishNewAttachment(
                                        gameAdminStore.selectedProject,
                                        gameAdminStore.selectedAttachment,
                                    );
                                }}
                            />
                        )}
                        <CardContent>
                            <TextField
                                disabled={isNotNullOrUndefined(gameAdminStore.selectedAttachment.id)}
                                fullWidth
                                variant={'outlined'}
                                required
                                id="attachment-description"
                                label="Описание"
                                value={selectedAttachment?.description}
                                onChange={(e) => {
                                    if (e.currentTarget.value.length > 32) {
                                        return;
                                    }
                                    runInAction(() => (selectedAttachment!.description = e.currentTarget.value));
                                    gameAdminStore.setSaveStatus(gameAdminStore.selectedAttachment?.id, false);
                                }}
                            />
                        </CardContent>
                        <CardContent>
                            <TextField
                                fullWidth
                                disabled={isNotNullOrUndefined(gameAdminStore.selectedAttachment.id)}
                                variant={'outlined'}
                                id="attachment-url"
                                label="Ссылка"
                                value={selectedAttachment?.url}
                                onChange={(e) => {
                                    runInAction(() => (selectedAttachment!.url = e.currentTarget.value));
                                    gameAdminStore.setSaveStatus(gameAdminStore.selectedAttachment?.id, false);
                                }}
                            />
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
});
