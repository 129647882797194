export class DelegateContract {
    public readonly id: number;
    private readonly _callback: (...args: any) => any;

    public constructor(callback: (...args: any) => any) {
        this.id = Math.round(Math.random() * Math.pow(10, 12));
        if (callback === undefined) {
            throw new Error("Callback value cannot be undefined!");
        }
        this._callback = callback;
    }

    public invoke(...args: any): any {
        return this._callback(...args);
    }

    public static equals(delegate1: DelegateContract, delegate2: DelegateContract) {
        return delegate1.id === delegate2.id;
    }
}

export abstract class Delegate {
    private readonly _container: DelegateContract[] = [];

    public add(callback: (...args: any) => any): DelegateContract {
        const delegate = new DelegateContract(callback);
        this._container.push(delegate);
        return delegate;
    }

    public remove(delegate: DelegateContract) {
        const targetIndex = this._container.findIndex(d => DelegateContract.equals(d, delegate));
        if (targetIndex === -1) {
            throw new Error(`Couldn't remove delegate ${delegate.id}!`);
        }
        this._container.splice(targetIndex);
        return delegate;
    }

    protected _invoke(...args: any): any {
        if (this._container.length === 0) {
            return;
        }

        for (let i = 0; i < this._container.length - 1; i++) {
            this._container[i].invoke(args);
        }
        return this._container[this._container.length - 1].invoke(args);
    }

    protected async _invokeAsync(...args: any): Promise<any> {
        if (this._container.length === 0) {
            return Promise.resolve();
        }

        for (let i = 0; i < this._container.length - 1; i++) {
            await this._container[i].invoke(args);
        }
        return await this._container[this._container.length - 1].invoke(args);
    }
}

export class Action extends Delegate {
    public add = (callback: () => void): DelegateContract => super.add(callback);
    public invoke = (): void => super._invoke();
}

export class Action1<T1> extends Delegate {
    public add = (callback: (arg1: T1) => void): DelegateContract => super.add(callback);
    public invoke = (arg1: T1): void => super._invoke(arg1);
}

export class Action2<T1, T2> extends Delegate {
    public add = (callback: (arg1: T1, arg2: T2) => void): DelegateContract => super.add(callback);
    public invoke = (arg1: T1, arg2: T2): void => super._invoke(arg1, arg2);
}

export class Action3<T1, T2, T3> extends Delegate {
    public add = (callback: (arg1: T1, arg2: T2, arg3: T3) => void): DelegateContract => super.add(callback);
    public invoke = (arg1: T1, arg2: T2, arg3: T3): void => super._invoke(arg1, arg2, arg3);
}

export class AsyncAction extends Delegate {
    public add = (callback: () => Promise<void>): DelegateContract => super.add(callback);
    public invokeAsync = (): Promise<void> => super._invokeAsync();
}

export class AsyncAction1<T1> extends Delegate {
    public add = (callback: (arg1: T1) => Promise<void>): DelegateContract => super.add(callback);
    public invokeAsync = (arg1: T1): Promise<void> => super._invokeAsync(arg1);
}

export class AsyncAction2<T1, T2> extends Delegate {
    public add = (callback: (arg1: T1, arg2: T2) => Promise<void>): DelegateContract => super.add(callback);
    public invokeAsync = (arg1: T1, arg2: T2): Promise<void> => super._invokeAsync(arg1, arg2);
}

export class AsyncAction3<T1, T2, T3> extends Delegate {
    public add = (callback: (arg1: T1, arg2: T2, arg3: T3) => Promise<void>): DelegateContract => super.add(callback);
    public invokeAsync = (arg1: T1, arg2: T2, arg3: T3): Promise<void> => super._invokeAsync(arg1, arg2, arg3);
}