import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import {
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { Header } from '../../Components/Header/Header';
import { useStores } from '../../Services/serviceRegistration';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import { EducationStageDto } from '../../Api';
import moment from 'moment';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { UserStore } from '../../Stores/UserStore';
import DeleteIcon from '@material-ui/icons/Delete';

class EducationPageStore {
    private _userStore: UserStore;

    public isBusy: boolean = false;
    public hasChanges: boolean = false;
    public educationStages: EducationStageDto[] = [];

    public constructor(userStore: UserStore) {
        makeAutoObservable(this);

        this._userStore = userStore;
        this.educationStages = this._userStore.currentUserDto?.education ?? [];

        reaction(
            () => this._userStore.currentUserDto,
            () => {
                if (isNotNullOrUndefined(this._userStore.currentUserDto)) {
                    this.educationStages = this._userStore.currentUserDto.education ?? [];
                }
            },
        );
    }

    public addEducationStage() {
        this.hasChanges = true;
        this.educationStages.push({
            typeOfDegree: '',
            fieldOfStudy: '',
            summary: '',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            institution: '',
        });
    }
}

export const EducationPage: React.FC = observer(() => {
    const { userStore } = useStores();
    const localStore = useLocalObservable<EducationPageStore>(() => new EducationPageStore(userStore));

    if (isNullOrUndefined(userStore.currentUser)) {
        return <></>;
    }

    return (
        <Container style={{ marginTop: '1rem', marginBottom: '3rem' }}>
            <form>
                <Card>
                    <Header
                        title={'Редактирование образования'}
                        description={'Укажите сведения о образовании'}
                        buttonTitle={'Сохранить'}
                        isButtonDisabled={localStore.isBusy || !localStore.hasChanges}
                        onButtonClick={() => {
                            runInAction(() => {
                                localStore.isBusy = true;
                            });
                            try {
                                void userStore.updateUserEducation(localStore.educationStages);
                            } finally {
                                runInAction(() => {
                                    localStore.isBusy = false;
                                    localStore.hasChanges = false;
                                });
                            }
                        }}
                    />
                    {localStore.educationStages.length === 0 && (
                        <Grid
                            container
                            direction={'column'}
                            alignContent={'center'}
                            style={{ margin: `2.5rem 0` }}
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <Typography variant={'h5'} style={{ alignSelf: 'center' }}>
                                    Добавить сведения о образовании
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ alignSelf: 'center' }}>
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    onClick={() => localStore.addEducationStage()}
                                >
                                    Добавить
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {localStore.educationStages.map((stage, index) => (
                        <CardContent key={index}>
                            <Paper variant={'outlined'} style={{ padding: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography variant={'body1'}>{`Образование ${index + 1}:`}</Typography>
                                        <IconButton
                                            title={'Удалить блок'}
                                            disabled={localStore.isBusy}
                                            onClick={() => {
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    localStore.educationStages.splice(index, 1);
                                                });
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant={'outlined'}
                                            id={`education-institution-${index}`}
                                            label="Название учебного заведения"
                                            value={stage.institution}
                                            onChange={(e) => {
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.institution = e.target.value;
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant={'outlined'}
                                            id={`education-field-of-study-${index}`}
                                            label="Специальность"
                                            value={stage.fieldOfStudy}
                                            onChange={(e) => {
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.fieldOfStudy = e.target.value;
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant={'outlined'}
                                            id={`education-type-of-degree-${index}`}
                                            label="Степень"
                                            value={stage.typeOfDegree}
                                            onChange={(e) => {
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.typeOfDegree = e.target.value;
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            required
                                            variant={'outlined'}
                                            id={`education-start-date-${index}`}
                                            label="Начало"
                                            type="date"
                                            defaultValue={stage.startDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.startDate = e.target.value;
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            required
                                            variant={'outlined'}
                                            id={`education-end-date-${index}`}
                                            label="Окончание"
                                            type="date"
                                            defaultValue={stage.endDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) =>
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.endDate = e.target.value;
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant={'outlined'}
                                            id={`education-summary-${index}`}
                                            label="Описание"
                                            multiline
                                            rows={4}
                                            value={stage.summary}
                                            onChange={(e) => {
                                                runInAction(() => {
                                                    localStore.hasChanges = true;
                                                    stage.summary = e.target.value;
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </CardContent>
                    ))}
                    {localStore.educationStages.length > 0 && (
                        <CardContent>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                onClick={() => localStore.addEducationStage()}
                            >
                                Добавить
                            </Button>
                        </CardContent>
                    )}
                </Card>
            </form>
        </Container>
    );
});
