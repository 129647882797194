
export class ValidationStream {
    public units: Array<IValidationUnit>;
    public cursor: number = 0;

    public ensureNonEmpty(onError?: string): ValidationStream {
        if (this.units[this.cursor].finished) return this;
        const actualValue = this.units[this.cursor].value;
        if (!actualValue) {
            this.units[this.cursor].error = !!onError ? onError : "Поле не может быть пустым!";
        }
        return this;
    }

    public skipIf(value: boolean): ValidationStream {
        this.units[this.cursor].finished = this.units[this.cursor].finished || value;
        return this;
    }

    public matchRegex(regexp: RegExp, onError: string): ValidationStream {
        if (this.units[this.cursor].finished) return this;
        const actualValue = this.units[this.cursor].value;
        if (!!actualValue && actualValue.search(regexp)) {
            this.units[this.cursor].error = onError;
        }
        return this;
    }

    public mustEqual(value: string | undefined, onError: string): ValidationStream {
        if (this.units[this.cursor].finished) return this;
        const actualValue = this.units[this.cursor].value;
        if (actualValue !== value) {
            this.units[this.cursor].error = onError;
        }
        return this;
    }
    
    public onError(errorCallback: (errors: string) => void): ValidationStream {
        const error = this.units[this.cursor].error;
        if (!!error) {
            errorCallback(error);
        } else {
            errorCallback("");
        }
        return this;
    }

    public getLastError(): string {
        return this.units[this.cursor].error;
    }

    public nextValue(value: string | undefined): ValidationStream {
        if (!value) {
            this.units.push(new ValidationUnit(""));
        } else {
            this.units.push(new ValidationUnit(value));
        }
        this.cursor += 1;
        return this;
    }

    public nextEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ValidationStream {
        this.units.push(new ValidationUnit(event.target.value));
        return this;
    }

    public isAllValid(): boolean {
        for (const unit of this.units) {
            if (!!unit.error)
                return false;
        }
        return true;
    }
    
    public constructor(sourceValue: string) {
        this.units = new Array<ValidationUnit>(new ValidationUnit(sourceValue));
    }
}

export function validateValue(value: string | undefined): ValidationStream {
    if (!!value) {
        return new ValidationStream(value);
    } else {
        return new ValidationStream("");
    }
}

export function validateEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): ValidationStream {
    return new ValidationStream(event.target.value);
}

export function validateAll(...args: string[]): boolean {
    for (const arg of args) {
        if (arg !== "") {
            return true;
        }
    }
    return false;
}

export interface IValidationUnit {
    value?: string;
    error: string;
    finished: boolean;
}

export class ValidationUnit implements IValidationUnit
{
    public value: string;
    public error: string;
    public finished: boolean;

    constructor(value: string) {
        this.value = value;
        this.error = "";
        this.finished = false;
    }
}


