import { UserStore } from './UserStore';
import { FpiGameStore } from './FpiGameStore';
import { makeAutoObservable, reaction } from 'mobx';
import {
    AttachmentDto,
    ContributionApi,
    ContributionDto,
    FpiRole,
    PlayerApi,
    PlayerDto,
    ProfileApi,
    ProjectApi,
    ProjectDto,
    UserDto,
    UserRole,
} from '../Api';
import { isNotNullOrUndefined, isNullOrUndefined } from '../Helpers/utils';
import { alertStore, historyService } from '../Services/serviceRegistration';
import { AlertType } from '../Components/AlertBar';
import { IOption } from '../Components/Dropdown/Dropdown';
import { History } from 'history';
import { IHistoryService } from '../Services/historyService';
import { Routes } from '../Components/Router/routes';

export interface TabOption extends IOption {
    url: string;
}

export interface IRatedPlayer {
    player: PlayerDto;
    tokenCount: number;
}

export class GameAdminStore {
    private _fpiGameStore: FpiGameStore;
    private _userStore: UserStore;
    private _projectApi: ProjectApi;
    private _contributionApi: ContributionApi;
    private _playerApi: PlayerApi;
    private _historyService: History;
    private _profileApi: ProfileApi;

    private _saveMap: Map<string, boolean> = new Map<string, boolean>();

    public isBusy: boolean = false;
    public users: UserDto[] = [];
    public selectedUser?: UserDto = undefined;
    public selectedPlayer?: PlayerDto = undefined;
    public selectedContribution?: ContributionDto = undefined;
    public selectedProject?: ProjectDto = undefined;
    public selectedAttachment?: AttachmentDto = undefined;
    public selectedTab: number = 0;
    public selectedPage: TabOption;
    public pageList: TabOption[];

    public constructor(
        fpiGameStore: FpiGameStore,
        userStore: UserStore,
        projectApi: ProjectApi,
        contributionApi: ContributionApi,
        playerApi: PlayerApi,
        historyService: IHistoryService,
        profileApi: ProfileApi,
    ) {
        this._fpiGameStore = fpiGameStore;
        this._userStore = userStore;
        this._projectApi = projectApi;
        this._contributionApi = contributionApi;
        this._playerApi = playerApi;
        this._historyService = historyService;
        this._profileApi = profileApi;

        this.pageList = [
            { key: '0', title: 'Проекты', url: Routes.Pages.FpiGame_ProjectManagement },
            { key: '1', title: 'Пользователи', url: Routes.Pages.FpiGame_PlayerManagement },
            { key: '2', title: 'Рейтинг', url: Routes.Pages.FpiGame_Rating },
        ];
        this.selectedPage = this.pageList[0];

        makeAutoObservable(this);

        reaction(
            () => this.selectedProject,
            () => {
                this.resetSelectedItems();
            },
        );
        reaction(
            () => this._userStore.currentUser,
            () => {
                this.resetSelectedItems(true);
            },
        );

        reaction(
            () => this._userStore.isUserProfileLoaded,
            () => {
                if (this._userStore.currentUser?.role === UserRole.Admin) {
                    void this.fetchUsers();
                }
            },
        );
    }

    public get playerRating(): IRatedPlayer[] {
        const rating = new Map<string, number>();
        const players = new Map<string, PlayerDto>();
        for (const project of this._fpiGameStore.projects) {
            for (const contribution of project?.records ?? []) {
                if (
                    isNotNullOrUndefined(contribution) &&
                    isNotNullOrUndefined(contribution.id) &&
                    isNotNullOrUndefined(contribution.player) &&
                    isNotNullOrUndefined(contribution.player.id) &&
                    isNotNullOrUndefined(contribution.reward)
                ) {
                    const prevValue = rating.get(contribution.player.id) ?? 0;
                    rating.set(contribution.player.id, prevValue + contribution.reward);
                    if (prevValue !== 0) {
                        players.set(contribution.player.id, contribution.player);
                    }
                }
            }
        }

        return this.users
            .map((u) =>
                isNotNullOrUndefined(u.id)
                    ? {
                          player: players.get(u.id) ?? (u as PlayerDto),
                          tokenCount: rating.get(u.id) ?? 0,
                      }
                    : { player: u as PlayerDto, tokenCount: 0 },
            )
            .sort((a, b) => {
                if (a.tokenCount > b.tokenCount) {
                    return -1;
                }
                if (a.tokenCount < b.tokenCount) {
                    return 1;
                }
                return 0;
            });
    }

    public switchPage(newPage: IOption | TabOption) {
        if (!('url' in newPage)) {
            return;
        }
        this.selectedPage = newPage;
        historyService.goTo(newPage.url);
    }

    public async fetchUsers(): Promise<void> {
        this.users = [];
        if (this._userStore.isUserProfileLoaded) {
            const response = await this._profileApi.getUsers();
            this.users = response.data;
        }
    }

    public isSaved(resourceId: string | null | undefined, fieldName: string | undefined = undefined): boolean {
        if (isNullOrUndefined(resourceId)) {
            return true;
        }
        const fullResourceId = isNullOrUndefined(fieldName) ? resourceId : resourceId + fieldName;

        const saveStatus = this._saveMap.get(fullResourceId);
        if (isNullOrUndefined(saveStatus)) {
            this._saveMap.set(fullResourceId, true);
            return true;
        }
        return saveStatus;
    }

    public setSaveStatus(
        resourceId: string | null | undefined,
        newStatus: boolean,
        fieldName: string | undefined = undefined,
    ): void {
        if (isNullOrUndefined(resourceId)) {
            return;
        }
        const fullResourceId = isNullOrUndefined(fieldName) ? resourceId : resourceId + fieldName;
        this._saveMap.set(fullResourceId, newStatus);
    }

    public resetSelectedItems(resetProject: boolean = false): void {
        this.selectedContribution = undefined;
        this.selectedUser = undefined;
        this.selectedAttachment = undefined;
        if (resetProject) {
            this.selectedProject = undefined;
        }
    }

    public createNewProject(): void {
        this.selectedProject = { title: 'Безымянный проект', description: 'Без описания' } as ProjectDto;
    }

    public async publishNewProject(project: ProjectDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNotNullOrUndefined(project.id) || isNullOrUndefined(project.title)) {
                return;
            }
            const response = await this._projectApi.createProject({
                title: project.title,
                description: project.description,
            });
            if (isNotNullOrUndefined(response.data.result)) {
                this.selectedProject = response.data.result;
                this._fpiGameStore.projects.push(this.selectedProject);
                this.setSaveStatus(response.data.result.id, true);
            } else {
                this.resetSelectedItems(true);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async updateProject(project: ProjectDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project.id)) {
                return;
            }
            const response = await this._projectApi.updateProject(project.id, {
                title: project.title,
                description: project.description,
            });
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(true);
            } else {
                this.setSaveStatus(project.id, true);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async modifyProjectRewards(project: ProjectDto, modifier: number): Promise<boolean> {
        try {
            if (isNullOrUndefined(project.id)) {
                return false;
            }
            const response = await this._projectApi.modifyProjectRewards(project.id, { modifier });
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(true);
                return false;
            } else {
                this._fpiGameStore.updateProjectInStore(response.data.result);
                this.selectedProject = response.data.result;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    public async updateUser(user: UserDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(user.id)) {
                return;
            }

            if (this.isSaved(user.id, 'role')) {
                await this._profileApi.updateUserById(user.id, {
                    adminCommentary: isNotNullOrUndefined(user.adminCommentary) ? user.adminCommentary : '',
                });
            }

            if (isNullOrUndefined(user.role) || isNullOrUndefined(user.userName)) {
                return;
            }

            if (this.isSaved(user.id, 'adminCommentary')) {
                await this._profileApi.changeUserRole({
                    userRole: user.role,
                    userName: user.userName,
                });
            }

            this.setSaveStatus(user.id, true, 'role');
            this.setSaveStatus(user.id, true, 'adminCommentary');
        } finally {
            this.isBusy = false;
        }
    }

    public createNewContribution(): void {
        this.selectedContribution = {
            reward: 0,
            player: undefined,
        };
    }

    public async publishNewContribution(contribution: ContributionDto): Promise<void> {
        try {
            this.isBusy = true;
            if (
                isNullOrUndefined(contribution.player) ||
                isNullOrUndefined(contribution.player.id) ||
                isNullOrUndefined(this.selectedProject) ||
                isNullOrUndefined(this.selectedProject.id) ||
                isNullOrUndefined(this.selectedProject.modifier)
            ) {
                alertStore.show('Не удалось добавить вклад. Заполните все поля!', AlertType.Warning);
                return;
            }
            const response = await this._contributionApi.createContribution({
                userId: contribution.player.id,
                reward: contribution.reward ?? 0,
                projectId: this.selectedProject.id,
            });
            if (isNotNullOrUndefined(response.data.result)) {
                this.selectedContribution = response.data.result;
                this.selectedProject?.records?.push(response.data.result);
                this.setSaveStatus(response.data.result.id, true);
            } else {
                this.resetSelectedItems(false);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async updateContribution(contribution: ContributionDto): Promise<void> {
        try {
            this.isBusy = true;
            if (
                isNullOrUndefined(contribution.id) ||
                isNullOrUndefined(contribution.player) ||
                isNullOrUndefined(contribution.player.id) ||
                isNullOrUndefined(this.selectedProject) ||
                isNullOrUndefined(this.selectedProject.id)
            ) {
                alertStore.show('Не удалось обновить вклад. Заполните все поля!', AlertType.Warning);
                return;
            }
            const response = await this._contributionApi.updateContribution(contribution.id, {
                userId: contribution.player.id,
                reward: contribution.reward ?? 0,
                projectId: this.selectedProject.id,
            });
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(false);
            } else {
                this.setSaveStatus(contribution.id, true);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async addContributor(project: ProjectDto, targetContributor: PlayerDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project.id) || isNullOrUndefined(targetContributor.id)) {
                return;
            }
            const response = await this._projectApi.addContributorToProject(project.id, {
                contributorId: targetContributor.id,
                role: FpiRole.Engineer,
            });
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(false);
            } else {
                this.selectedProject = response.data.result;
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async removeContributor(project: ProjectDto, targetContributor: PlayerDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project.id) || isNullOrUndefined(targetContributor.id)) {
                return;
            }
            const response = await this._projectApi.deleteContributor(project.id, targetContributor.id);
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(false);
            } else {
                this.selectedProject = response.data.result;
                this._fpiGameStore.updateProjectInStore(response.data.result);
                this.selectedContribution = undefined;
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async updatePlayer(project: ProjectDto, targetContributor: PlayerDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project.id) || isNullOrUndefined(targetContributor.id)) {
                return;
            }
            const response = await this._projectApi.updateContributor(project.id, targetContributor.id, {
                projectRole: targetContributor.projectRole,
            });
            if (isNullOrUndefined(response.data.result)) {
                this.resetSelectedItems(false);
            } else {
                this.setSaveStatus(targetContributor.id, true);
                this.selectedProject = response.data.result;
                this._fpiGameStore.updateProjectInStore(response.data.result);
                this.selectedPlayer = response.data.result.contributors?.find((c) => c.id === targetContributor.id);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public async publishNewAttachment(
        project: ProjectDto | null | undefined,
        attachment: AttachmentDto | null | undefined,
    ): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project) || isNullOrUndefined(project.id) || isNullOrUndefined(attachment)) {
                alertStore.show('Не удалось добавить доп. информацию. Что-то пошло не так!', AlertType.Warning);
                return;
            }
            const response = await this._projectApi.addAttachmentToProject(project.id, {
                url: attachment.url,
                description: attachment.description,
            });
            if (isNotNullOrUndefined(response.data.project) && isNotNullOrUndefined(response.data.attachment)) {
                this.selectedAttachment = response.data.attachment;
                this.selectedProject?.attachments?.push(response.data.attachment);
                this.setSaveStatus(response.data.attachment.id, true);
            } else {
                this.resetSelectedItems(false);
            }
        } finally {
            this.isBusy = false;
        }
    }

    public createNewAttachment(): void {
        this.selectedAttachment = {
            url: '',
            description: '',
        };
    }

    public async removeAttachment(project: ProjectDto, attachment: AttachmentDto): Promise<void> {
        try {
            this.isBusy = true;
            if (isNullOrUndefined(project.id) || isNullOrUndefined(attachment.id)) {
                return;
            }
            const response = await this._projectApi.deleteAttachment(project.id, attachment.id);
            if (isNullOrUndefined(response.data.project) || isNullOrUndefined(response.data.removedElement)) {
                this.resetSelectedItems(false);
            } else {
                this.selectedProject = response.data.project;
                this._fpiGameStore.updateProjectInStore(response.data.project);
                this.selectedAttachment = undefined;
            }
        } finally {
            this.isBusy = false;
        }
    }
}
