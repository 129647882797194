import React, { useMemo } from 'react';
import AppRouter from "./Components/Router";
import {
    createTheme,
    ThemeProvider
} from "@material-ui/core";
import { hot } from "react-hot-loader/root";
import { observer } from 'mobx-react';
import { configureApp } from './Startup';
import { AlertBar } from './Components/AlertBar';

configureApp();
const App = observer(() => {
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: '#133A89',
                    },
                    secondary: {
                        main: '#F24405',
                    },
                },
            }),
        []
    );
    return (
        <ThemeProvider theme={theme}>
            <AppRouter />
            <AlertBar />
        </ThemeProvider>
    );
});

export default hot(App);
