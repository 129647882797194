import React, { useCallback, useEffect } from 'react';
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';
import { useLayoutStyles } from './styles';
import { observer } from 'mobx-react';
import { goTo } from '../../Helpers/history';
import { authService } from '../../Services/serviceRegistration';
import { Routes } from '../Router/routes';
import { UserPanel } from '../UserPanel';

export const MainLayout: React.FC = observer((props: any) => {
    const classes = useLayoutStyles();

    const initLayout = useCallback(async () => {
        const user = await authService.fetchUserProfile();
        if (!user) {
            goTo(Routes.Pages.Login);
        }
    }, []);

    useEffect(() => {
        void initLayout();
    }, []);

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.title}>
                        <Typography
                            className={classes.titleTypography}
                            variant="h6"
                            component="a"
                            href={Routes.Pages.FpiGame_PlayerDashboard}
                        >
                            Фабрика перспективных идей
                        </Typography>
                    </div>
                    <UserPanel />
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">{props.children}</Container>
        </div>
    );
});
