import { AuthService } from './authService';
import { Context, createContext, useContext } from 'react';
import {
    AuthApi,
    Configuration,
    ConfigurationParameters,
    ContributionApi,
    PlayerApi,
    ProfileApi,
    ProjectApi,
} from '../Api';
import { UserStore } from '../Stores/UserStore';
import { FpiGameStore } from '../Stores/FpiGameStore';
import { GameAdminStore } from '../Stores/GameAdminStore';
import { AlertStore } from './alertStore';
import { SuggestionService } from './suggestionService';
import { createHistoryService, IHistoryService } from './historyService';
import { ExportService } from './exportService';

export const historyService: IHistoryService = createHistoryService();
export const baseConfiguration: ConfigurationParameters = {
    apiKey: (): string => {
        return 'Bearer ' + localStorage.getItem(AuthService.jwtTokenKey) || '';
    },
    basePath: process.env.NODE_ENV === 'development' ? 'http://localhost:85' : process.env.REACT_APP_URL,
};

export const apiConfiguration = new Configuration(baseConfiguration);
export const authApi = new AuthApi(apiConfiguration);
export const profileApi = new ProfileApi(apiConfiguration);
export const projectApi = new ProjectApi(apiConfiguration);
export const contributionApi = new ContributionApi(apiConfiguration);
export const playerApi = new PlayerApi(apiConfiguration);
export const userStore = new UserStore(profileApi);
export const authService = new AuthService(authApi, profileApi, userStore);
export const fpiGameStore = new FpiGameStore(projectApi, userStore);
export const gameAdminStore = new GameAdminStore(
    fpiGameStore,
    userStore,
    projectApi,
    contributionApi,
    playerApi,
    historyService,
    profileApi,
);
export const alertStore = new AlertStore();
export const suggestionService = new SuggestionService(alertStore, userStore);
export const exportService = new ExportService(fpiGameStore, gameAdminStore);

export const rootStore = createContext({
    userStore,
    fpiGameStore,
    gameAdminStore,
    alertStore,
});

type ExtractContext<T> = T extends Context<infer T> ? T : never;
export type RootStore = ExtractContext<typeof rootStore>;
export const useStores = () => useContext(rootStore);
