import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardContent, Container, TextField } from '@material-ui/core';
import { Header } from '../../Components/Header/Header';
import { useStores } from '../../Services/serviceRegistration';

interface ILuggagePageState {
    isBusy: boolean;
    hasChanges: boolean;
    luggage: string;
}

export const LuggagePage: React.FC = observer(() => {
    const { userStore } = useStores();
    const [state, setState] = useState<ILuggagePageState>({ isBusy: false, hasChanges: false, luggage: '' });

    useEffect(() => {
        setState((s) => ({ ...s, luggage: userStore.currentUserDto?.luggage ?? '' }));
    }, [userStore.currentUserDto]);

    return (
        <Container style={{ marginTop: '1rem' }}>
            <Card>
                <Header
                    title={'Редактирование багажа'}
                    description={'Заполните здесь информацию о вашей научной степени, патентах и т.п.'}
                    buttonTitle={'Сохранить'}
                    isButtonDisabled={state.isBusy || !state.hasChanges}
                    onButtonClick={() => {
                        setState((s) => ({ ...s, isBusy: true }));
                        try {
                            void userStore.updateUserLuggage(state.luggage);
                        } finally {
                            setState((s) => ({ ...s, isBusy: false, hasChanges: false }));
                        }
                    }}
                />
                <CardContent>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        id="user-luggage"
                        label="Багаж"
                        multiline
                        rows={12}
                        value={state.luggage}
                        onChange={(e) => {
                            setState((s) => ({ ...s, hasChanges: true, luggage: e.target.value }));
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    );
});
