import { makeAutoObservable } from 'mobx';
import { User } from '../Models/Profile/User';
import { EducationStageDto, ProfileApi, UserDto } from '../Api';
import { isNotNullOrUndefined } from '../Helpers/utils';

export class UserStore {
    private _profileApi: ProfileApi;

    public currentUser?: User = undefined;
    public currentUserDto?: UserDto = undefined;
    public isUserProfileLoaded: boolean = false;

    public constructor(profileApi: ProfileApi) {
        this._profileApi = profileApi;
        makeAutoObservable(this);
    }

    public setUser(isLoaded: boolean, user: UserDto | undefined): User | undefined {
        this.isUserProfileLoaded = isLoaded;
        this.currentUser = isNotNullOrUndefined(user) ? new User(user) : undefined;
        this.currentUserDto = user;
        return this.currentUser;
    }

    public async updateUserExperience(newExperience: string): Promise<void> {
        const response = await this._profileApi.updateUser({
            experience: newExperience,
        });
        if (isNotNullOrUndefined(response.data.result)) {
            this.setUser(this.isUserProfileLoaded, response.data.result);
        }
    }

    public async updateUserLuggage(newLuggage: string): Promise<void> {
        const response = await this._profileApi.updateUser({
            luggage: newLuggage,
        });
        if (isNotNullOrUndefined(response.data.result)) {
            this.setUser(this.isUserProfileLoaded, response.data.result);
        }
    }

    public async updateUserEducation(newEducationRecords: EducationStageDto[]): Promise<void> {
        const response = await this._profileApi.updateUser({
            education: newEducationRecords,
        });
        if (isNotNullOrUndefined(response.data.result)) {
            this.setUser(this.isUserProfileLoaded, response.data.result);
        }
    }
}
