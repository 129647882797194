import { useState, useEffect } from "react";
import { AlertType } from "../../Components/AlertBar";
import { alertStore, historyService, profileApi } from '../../Services/serviceRegistration';
import { validateValue } from "../../Helpers/validationSupport";
import { ConfirmChangeEmailViewModel } from '../../Api';
import { Routes } from '../../Components/Router/routes';

export class ChangeEmailFormState {
    public email?: string;
    public emailError?: string;
}

export function useActions() {    
    const [formState, setFormState] = useState(new ChangeEmailFormState());
    const [isBusy, setIsBusy] = useState(false);

    const query = new URLSearchParams(historyService.location.search);
    const emailFromQuery = query.get("email");

    useEffect(() => {
        if (!!emailFromQuery) {
            setFormState(v => ({ ...v, email: emailFromQuery }));
        }
    }, []);

    const handleSubmit = async () => {
        setIsBusy(true);
        try {

            const token = query.get('token');
            const email = query.get("email");

            if (validateEmail() || token === null || email === null) {
                alertStore.show("Что-то не так с запросом. Попробуйте запустить процедуру на странице профиля заново!", AlertType.Error);
                return;
            }

            const data: ConfirmChangeEmailViewModel = { newEmail: email, token: token };
            const response = await profileApi.confirmChangeEmail(data);
            if (!!response) {
                switch (response.status) {
                    case 404:
                    case 400: 
                        alertStore.show("Что-то не так с запросом. Попробуйте запустить процедуру на странице профиля заново!", AlertType.Error);
                        break;
                    case 409: 
                        alertStore.show("Пользователь с такой почтой уже существует!", AlertType.Error);
                        break;
                    case 200:
                        alertStore.show("Смена электронного почтового ящика успешно завершена!", AlertType.Success);
                        historyService.push(Routes.Pages.Login);
                        break;
                }
            }
        } finally {
            setIsBusy(false);
        }
    };
        
    const validateEmail = () => validateValue(formState.email)
        .ensureNonEmpty()
        .matchRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Не похоже на почту :(")           
        .getLastError();

    return { emailFromQuery, formState, setFormState, handleSubmit, isBusy };
}
