import React from 'react';
import {
    FormControl,
    CardActions,
    Button,
    CardContent,
    Card,
    Typography,
    TextField,
    Link,
    Box,
    LinearProgress,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useActions } from './actions';
import { Routes } from '../../Components/Router/routes';

export default function LoginPage() {
    const classes = useStyles();
    const { formState, setFormState, handleSubmit, isBusy } = useActions();

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Card raised={true}>
                    <LinearProgress hidden={!isBusy} />
                    <CardContent>
                        <Typography variant="h5" className={classes.header}>
                            ФПИ - Вход
                        </Typography>
                        <div>
                            <FormControl>
                                <TextField
                                    disabled={isBusy}
                                    error={!!formState.emailError}
                                    helperText={formState.emailError}
                                    label="Email"
                                    variant="outlined"
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            email: e.target.value,
                                        })
                                    }
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl>
                                <TextField
                                    disabled={isBusy}
                                    error={!!formState.passwordError}
                                    label="Пароль"
                                    type="password"
                                    helperText={formState.passwordError}
                                    variant="outlined"
                                    onChange={(e) =>
                                        setFormState({
                                            ...formState,
                                            password: e.target.value,
                                        })
                                    }
                                />
                            </FormControl>
                        </div>
                    </CardContent>
                    <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isBusy}
                            className={classes.submitButton}
                            variant="contained"
                            color="primary"
                        >
                            Войти
                        </Button>
                    </CardActions>
                </Card>
            </form>
            <Typography component="div" className={classes.restoreLink}>
                <Box fontSize={'0.8rem'}>
                    <Link href="https://akmetron.ru/">АО Акметрон</Link>
                    {' | '}
                    <Link href={Routes.Pages.ForgotPassword + (!!formState.email ? '?email=' + formState.email : '')}>
                        Восстановить пароль
                    </Link>
                </Box>
            </Typography>
        </div>
    );
}

export class LoginFormState {
    public email?: string;
    public password?: string;
    public emailError?: string;
    public passwordError?: string;
}
