import { useState, useEffect } from 'react';
import { BadResponse } from '../../Models/Common/BadResponse';
import { AlertType } from '../../Components/AlertBar';
import { validateValue, validateAll } from '../../Helpers/validationSupport';
import { RegisterFormState } from '.';
import { goTo } from '../../Helpers/history';
import { historyService, authService, authApi, alertStore } from '../../Services/serviceRegistration';
import { Routes } from '../../Components/Router/routes';
import { RegisterViewModel } from '../../Api';

export function useActions() {
    const [firstTimeRun, setFirstTimeRunState] = useState(true);
    const [formState, setFormState] = useState(new RegisterFormState());
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        const checkUserToken = async () => {
            const user = await authService.fetchUserProfile();
            if (!!user) {
                goTo(Routes.Pages.FpiGame_PlayerDashboard);
            }
        };
        void checkUserToken();
    }, []);

    const handleSubmit = async () => {
        setIsBusy(true);
        try {
            if (
                validateAll(
                    validateEmail(),
                    validateUsername(),
                    validatePassword(),
                    validatePasswordRepeat(),
                    validateLastName(),
                    validateFirstName(),
                    validateCompanyName(),
                    validatePhoneNumber(),
                )
            ) {
                alertStore.show('Не все поля заполнены верно!', AlertType.Error);
                return;
            }

            const data: RegisterViewModel = {
                login: formState.username!,
                email: formState.email!,
                firstName: formState.firstName!,
                lastName: formState.lastName!,
                patronymic: formState.patronymic!,
                phoneNumber: formState.phoneNumber!,
                companyName: formState.companyName!,
                password: formState.password!,
            };
            const response = await authApi.register(data, { culture: 'ru-RU' });
            if (!!response) {
                switch (response.status) {
                    case 201:
                        alertStore.show('Регистрация прошла успешна!', AlertType.Success);
                        historyService.push(Routes.Pages.CheckEmail);
                        break;
                    default:
                        if (response.data.isRight) {
                            let errorResponse = new BadResponse(response.data.right!);
                            let errorString =
                                errorResponse.firstError !== undefined ? errorResponse.firstError : errorResponse.title;
                            alertStore.show(errorString, AlertType.Error);
                        }
                        break;
                }
            }
        } finally {
            setIsBusy(false);
        }
    };

    const validateNotEmpty = (value?: string) =>
        validateValue(value).skipIf(firstTimeRun).ensureNonEmpty().getLastError();

    const validateUsername = () => validateNotEmpty(formState.username);
    const validateLastName = () => validateNotEmpty(formState.lastName);
    const validateFirstName = () => validateNotEmpty(formState.firstName);
    const validateCompanyName = () => validateNotEmpty(formState.companyName);

    const validateEmail = () =>
        validateValue(formState.email)
            .skipIf(firstTimeRun)
            .ensureNonEmpty()
            .matchRegex(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Не похоже на почту :(')
            .getLastError();

    const validatePassword = () =>
        validateValue(formState.password)
            .skipIf(firstTimeRun)
            .ensureNonEmpty()
            .mustEqual(formState.passwordRepeat, 'Пароли не совпадают')
            .getLastError();

    const validatePasswordRepeat = () =>
        validateValue(formState.passwordRepeat)
            .skipIf(firstTimeRun)
            .ensureNonEmpty()
            .mustEqual(formState.password, 'Пароли не совпадают')
            .getLastError();

    const validatePhoneNumber = () =>
        validateValue(formState.phoneNumber)
            .skipIf(firstTimeRun)
            .ensureNonEmpty()
            .matchRegex(/^((\+7|7|8)+([0-9]){10})$/, 'Введите валидный номер')
            .getLastError();

    useEffect(() => {
        setFirstTimeRunState(false);
    }, [firstTimeRun]);

    useEffect(() => {
        setFormState((v) => ({ ...v, usernameError: validateUsername() }));
    }, [formState.username]);

    useEffect(() => {
        setFormState((v) => ({ ...v, lastNameError: validateLastName() }));
    }, [formState.lastName]);

    useEffect(() => {
        setFormState((v) => ({ ...v, firstNameError: validateFirstName() }));
    }, [formState.firstName]);

    useEffect(() => {
        setFormState((v) => ({ ...v, companyNameError: validateCompanyName() }));
    }, [formState.companyName]);

    useEffect(() => {
        setFormState((v) => ({ ...v, phoneNumberError: validatePhoneNumber() }));
    }, [formState.phoneNumber]);

    useEffect(() => {
        setFormState((v) => ({ ...v, emailError: validateEmail() }));
    }, [formState.email]);

    useEffect(() => {
        setFormState((v) => ({
            ...v,
            passwordError: validatePassword(),
            passwordRepeatError: validatePasswordRepeat(),
        }));
    }, [formState.password, formState.passwordRepeat]);

    return { formState, setFormState, handleSubmit, isBusy };
}
