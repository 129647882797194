import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { PermissionSupport } from '../../Components/PermissionSupport';
import { UserRole } from '../../Api';
import {
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { exportService, useStores } from '../../Services/serviceRegistration';
import { useGameManagementStyles } from './GameManagementStyles';
import { Dropdown } from '../../Components/Dropdown/Dropdown';
import { Coin } from '../../Components/Coin/Coin';
import { ReportKind } from '../../Models/Reports/ReportKindEnum';
import { downloadBlobAsFile } from '../../Helpers/utils';

export const RatingPage: React.FC = observer(() => {
    const { gameAdminStore } = useStores();
    useEffect(() => {
        gameAdminStore.switchPage(gameAdminStore.pageList[2]);
    }, []);
    const classes = useGameManagementStyles();

    return (
        <Container>
            <PermissionSupport allow={[UserRole.Admin]}>
                <Grid container spacing={2} style={{ marginTop: '1rem' }} alignContent={'stretch'}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Dropdown
                                    render={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    renderOption={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto 0' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    options={gameAdminStore.pageList}
                                    value={gameAdminStore.selectedPage}
                                    onChange={(option) => {
                                        gameAdminStore.switchPage(option);
                                    }}
                                />
                            </CardContent>
                            <List className={classes.reducedLeftColumn}>
                                {gameAdminStore.playerRating.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="В системе нет игроков"
                                            secondary="Пригласите участников для начала работы"
                                        />
                                    </ListItem>
                                )}
                                {gameAdminStore.playerRating.map((ratedPlayer) => (
                                    <ListItem
                                        button
                                        key={ratedPlayer.player.id}
                                        selected={
                                            !!gameAdminStore.selectedUser &&
                                            gameAdminStore.selectedUser.id === ratedPlayer.player.id
                                        }
                                    >
                                        <Coin totalRate={ratedPlayer.tokenCount} />
                                        <ListItemText
                                            primary={`${ratedPlayer.player.lastName} ${ratedPlayer.player.firstName}`}
                                            secondary={`${ratedPlayer.player.userEmail}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>

                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant={'outlined'}
                                            onClick={async () => {
                                                const blob = await exportService.generateReport(ReportKind.ByProject);
                                                downloadBlobAsFile('project-rating.xlsx', blob);
                                            }}
                                        >
                                            Отчет по проектам
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant={'outlined'}
                                            onClick={async () => {
                                                const blob = await exportService.generateReport(ReportKind.ByUser);
                                                downloadBlobAsFile('user-token-rating.xlsx', blob);
                                            }}
                                        >
                                            Отчет по пользователям
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </PermissionSupport>
        </Container>
    );
});
