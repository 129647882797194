export class Token {
    public name: string;
    public role: string;
    public timestamp: Date;
    public issuer: string;
    public audience: string;

    constructor(tokenSource: any) {
        this.name = tokenSource["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        this.role = tokenSource["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        this.timestamp = tokenSource["exp"];
        this.issuer = tokenSource["iss"];
        this.audience = tokenSource["aud"];
    }
}