import { Box, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { isNotNullOrUndefined } from '../../Helpers/utils';

export enum CardRowType {
    Default,
    Arrow,
}

export interface ICardRowProps {
    label?: string;
    title?: string;
    type?: CardRowType;
    description?: string;
    descriptionColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
    children?: JSX.Element;
}

export const CardRow: React.FC<ICardRowProps> = (props: ICardRowProps) => {
    return (
        <>
            <CardContent>
                <Grid container justifyContent={'space-between'} alignItems="center">
                    {isNotNullOrUndefined(props.label) && (
                        <Grid item xs={3}>
                            <Typography
                                variant="overline"
                                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                            >
                                {props.label}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={isNotNullOrUndefined(props.label) ? 8 : 11}>
                        {!!props.children ? (
                            props.children
                        ) : (
                            <>
                                <Typography
                                    variant="body1"
                                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                >
                                    {props.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        opacity: '0.5',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}
                                    color={props.descriptionColor}
                                >
                                    {props.description}
                                </Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={1}>
                        {props.type === CardRowType.Arrow && (
                            <Box display="flex" justifyContent="flex-end">
                                <ArrowForwardIosIcon />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </>
    );
};
