import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ProjectDto } from '../../Api';
import { useUrlState } from '../../Helpers/useUrlState';
import { isEmptyString, isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import { Card, CardContent, Container, TextField, Typography } from '@material-ui/core';
import { Header } from '../../Components/Header/Header';
import { alertStore, useStores } from '../../Services/serviceRegistration';
import { goTo } from '../../Helpers/history';
import { Routes } from '../../Components/Router/routes';
import { AlertType } from '../../Components/AlertBar';

interface IModifyRewardsPageState {
    isBusy: boolean;
    hasChanges: boolean;
    selectedProject?: ProjectDto;
    modifierFieldValue?: string;
    modifierFieldError?: string;
}

interface IModifyRewardsPageUrlState {
    projectId?: string;
}

export const ModifyRewardsPage: React.FC = observer(() => {
    const { fpiGameStore, gameAdminStore } = useStores();
    const [urlState] = useUrlState<IModifyRewardsPageUrlState>({ projectId: undefined });
    const [state, setState] = useState<IModifyRewardsPageState>({
        isBusy: false,
        selectedProject: undefined,
        hasChanges: false,
        modifierFieldValue: '1',
        modifierFieldError: undefined,
    });

    useEffect(() => {
        if (isNullOrUndefined(urlState.projectId) || fpiGameStore.projects.length === 0) {
            return;
        }
        setState((s) => ({
            ...s,
            selectedProject: fpiGameStore.projects.find((p) => p.id === urlState.projectId),
        }));
    }, [urlState.projectId, fpiGameStore.projects]);

    useEffect(() => {
        if (isEmptyString(state.modifierFieldValue)) {
            setState((s) => ({ ...s, modifierFieldError: 'Значение не может быть пустым!' }));
            return;
        }
        if (isNaN(+state.modifierFieldValue!)) {
            setState((s) => ({ ...s, modifierFieldError: 'Значение не является числом!' }));
            return;
        }
        setState((s) => ({ ...s, modifierFieldError: undefined }));
    }, [state.modifierFieldValue]);

    return (
        <Container style={{ marginTop: '1rem' }} maxWidth="sm">
            {isNullOrUndefined(state.selectedProject) ? (
                <Card>
                    <Typography style={{ margin: '6rem 0', textAlign: 'center' }} variant={'h5'}>
                        Не удается получить доступ к указанному проекту!
                    </Typography>
                </Card>
            ) : (
                <Card>
                    <Header
                        title={'Применить модификатор'}
                        description={
                            'Укажите значение модификатора для выбранного проекта, чтобы увеличить все вклады проекта на данное значение'
                        }
                        buttonTitle={'Применить'}
                        isButtonDisabled={
                            state.isBusy || !state.hasChanges || isNotNullOrUndefined(state.modifierFieldError)
                        }
                        onButtonClick={async () => {
                            setState((s) => ({ ...s, isBusy: true }));
                            const isSuccess = await gameAdminStore.modifyProjectRewards(
                                state.selectedProject!,
                                +state.modifierFieldValue!,
                            );
                            if (!isSuccess) {
                                alertStore.show(
                                    'Не удается применить мультипликатор к проекту в связи с неизвестной ошибкой!',
                                    AlertType.Error,
                                );
                            } else {
                                alertStore.show(
                                    'Коэффициент был успешно применен к проекту!',
                                    AlertType.Success,
                                );
                            }
                            goTo(Routes.Pages.FpiGame_ProjectManagement);
                        }}
                    />
                    <CardContent>
                        <TextField
                            disabled
                            fullWidth
                            variant={'outlined'}
                            id="project-name"
                            label="Имя выбранного проекта"
                            value={state.selectedProject.title}
                        />
                    </CardContent>
                    <CardContent>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            id="project-reward-modifier"
                            label="Мультипликатор"
                            value={state.modifierFieldValue}
                            error={isNotNullOrUndefined(state.modifierFieldError)}
                            helperText={state.modifierFieldError}
                            onChange={(e) => {
                                setState((s) => ({
                                    ...s,
                                    hasChanges: true,
                                    modifierFieldValue: e.target.value.trim(),
                                }));
                            }}
                        />
                    </CardContent>
                </Card>
            )}
        </Container>
    );
});
