import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { goTo } from '../../Helpers/history';
import { authService, useStores } from '../../Services/serviceRegistration';
import { observer } from 'mobx-react';
import { Routes } from '../Router/routes';
import { UserRole } from '../../Api';

export interface IUserPanelProps {
    isCartVisible?: boolean;
}

export const UserPanel: React.FC<IUserPanelProps> = observer(() => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { userStore } = useStores();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {userStore.currentUser === undefined ? (
                ''
            ) : (
                <div>
                    <Tooltip title="Меню профиля" aria-label="account of current user">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem disabled>{userStore.currentUser.userName}</MenuItem>
                        <MenuItem disabled>{userStore.currentUser.userEmail}</MenuItem>
                        {userStore.currentUser.role === UserRole.Admin && (
                            <MenuItem
                                onClick={(_) => {
                                    setAnchorEl(null);
                                    goTo(Routes.Pages.FpiGame_ProjectManagement);
                                }}
                            >
                                Администрирование
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={(_) => {
                                setAnchorEl(null);
                                goTo(Routes.Pages.Profile);
                            }}
                        >
                            Личный профиль
                        </MenuItem>
                        <MenuItem onClick={(_) => authService.doLogout(Routes.Pages.Login)}>Выйти</MenuItem>
                    </Menu>
                </div>
            )}
        </div>
    );
});
