import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { isNotNullOrUndefined } from '../../Helpers/utils';
import { ArrowDropDown } from '@material-ui/icons';

export interface IOption {
    key: string;
    title: string;
}

export interface IDropdownProps<T = IOption> {
    render?: (selectedOption: T) => JSX.Element;
    renderOption?: (option: T) => JSX.Element;
    options: T[];
    value: T | undefined;
    onChange?: (selected: T) => void;
}

export const Dropdown: React.FC<IDropdownProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option: IOption | {}) => {
        setAnchorEl(null);

        if ('key' in option && option.key !== props.value?.key) {
            props.onChange?.call(this, option);
        }
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                style={{
                    textTransform: 'none',
                }}
                endIcon={<ArrowDropDown />}
            >
                {isNotNullOrUndefined(props.render) && isNotNullOrUndefined(props.value)
                    ? props.render(props.value)
                    : props.value?.title}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={(object) => handleClose(object)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {props.options.map((option) => {
                    return (
                        <MenuItem
                            key={option.key}
                            selected={props.value?.key === option.key}
                            onClick={() => handleClose(option)}
                        >
                            {isNotNullOrUndefined(props.renderOption) ? props.renderOption(option) : option.title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
