import React from "react";
import {
    FormControl,
    CardActions,
    Button,
    CardContent,
    Card,
    Typography,
    TextField,
    Link,
    Box,
    LinearProgress
} from "@material-ui/core";
import { useActions } from "./actions";
import { useStyles } from "../LoginPage/styles";

export default function ForgotPasswordPage() {
    const classes = useStyles();
    const { emailFromQuery, handleSubmit, isBusy, formState, setFormState } = useActions();

    return (
        <div className={classes.root}>
            <Card raised>
                <LinearProgress hidden={!isBusy} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Восстановить пароль
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        Введите адрес электронной почты для которой необходимо изменить пароль.
                        А мы вышлем вам ссылку на эту почту для восстановления доступа.
                    </Typography>
                </CardContent>
                <CardContent>
                    <div>
                        <FormControl>
                            <TextField
                                disabled={isBusy}
                                error={!!formState.emailError}
                                helperText={formState.emailError}
                                label="Электронная почта"
                                variant="outlined"
                                defaultValue={emailFromQuery}
                                onChange={e =>
                                    setFormState({
                                        ...formState,
                                        email: e.target.value
                                    })
                                }
                            />
                        </FormControl>
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        onClick={handleSubmit}
                        disabled={isBusy}
                        className={classes.submitButton}
                        variant="contained"
                        color="primary"
                    >
                        Восстановить
                    </Button>
                </CardActions>
            </Card>
            <Typography component="div" className={classes.restoreLink}>
                <Box fontSize={"0.8rem"}>
                    <Link href="https://akmetron.ru/">АО Акметрон</Link>
                </Box>
            </Typography>
        </div>
    );
}
