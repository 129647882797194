import { Workbook } from 'exceljs';
import { ReportKind } from '../Models/Reports/ReportKindEnum';
import { PlayerDto } from '../Api';
import { FpiGameStore } from '../Stores/FpiGameStore';
import { projectRoleToRussian } from '../Models/FpiGame/ProjectRoleEnumHelper';
import { GameAdminStore } from '../Stores/GameAdminStore';

export class ExportService {
    private _fpiGameStore: FpiGameStore;
    private _gameAdminStore: GameAdminStore;

    public constructor(fpiGameStore: FpiGameStore, gameAdminStore: GameAdminStore) {
        this._fpiGameStore = fpiGameStore;
        this._gameAdminStore = gameAdminStore;
    }

    public async generateReport(kind: ReportKind): Promise<Blob> {
        const workbook = new Workbook();

        switch (kind) {
            case ReportKind.ByProject: {
                const sheet = workbook.addWorksheet('По проектам');

                sheet.columns = [
                    { header: 'Проект', key: 'project', width: 32 },
                    { header: 'ФИО', key: 'fullName', width: 32 },
                    { header: 'Роль', key: 'role', width: 16 },
                    { header: 'Токены', key: 'tokenNumber' },
                ];

                for (const ratedProject of this._fpiGameStore.projectRating) {
                    sheet.addRow({ project: ratedProject.project.title, tokenNumber: ratedProject.tokenNumber });
                    sheet.addRow({ fullName: 'Фабрика', role: '-', tokenNumber: ratedProject.tokenNumber / 2 });
                    for (const contributor of ratedProject.project.contributors ?? []) {
                        const tokenNumber = this._fpiGameStore.getPlayerRate(contributor, ratedProject.project);
                        sheet.addRow({
                            fullName: `${contributor.lastName} ${contributor.firstName} ${contributor.patronymic}`,
                            role: projectRoleToRussian(contributor.projectRole),
                            tokenNumber: tokenNumber,
                        });
                    }
                }
                break;
            }
            case ReportKind.ByUser: {
                const sheet = workbook.addWorksheet('По токенам');
                sheet.columns = [
                    { header: 'ФИО', key: 'fullName', width: 32 },
                    { header: 'Эл. почта', key: 'email', width: 16 },
                    { header: 'Токены', key: 'tokenNumber' },
                ];

                for (const user of this._gameAdminStore.users) {
                    const player = user as PlayerDto;
                    const tokenNumber = this._fpiGameStore.getPlayerRate(player, this._fpiGameStore.projects);
                    sheet.addRow({
                        fullName: `${player.lastName} ${player.firstName} ${player.patronymic}`,
                        email: player.userEmail,
                        tokenNumber: tokenNumber,
                    });
                }
                break;
            }
        }

        const buffer = await workbook.xlsx.writeBuffer();
        return new Blob([buffer]);
    }
}
