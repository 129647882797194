import { useState } from 'react';
import { goTo } from './history';
import { AsyncAction1 } from '../Models/Common/Delegates';

export class UrlState<S> {
    private readonly _initialState: S;
    private _actualState: S;
    public static readonly OnPopState: AsyncAction1<string> = new AsyncAction1<string>();

    public constructor(initialState: S) {
        this._initialState = initialState;
        this._actualState = initialState;
        this.Rebuild();
    }

    public Rebuild(): Readonly<S>  {
        this._actualState = UrlState.ParseUrl(this._actualState);
        return Object.freeze(this._actualState);
    }

    public Update(updatedState: Partial<S>): Readonly<S> {
        const nextState = { ...updatedState };
        const urlParams = UrlState.GenerateUrlParams(nextState);

        this._actualState = { ...this._actualState, ...nextState };
        goTo(window.location.pathname, urlParams.toString());
        return Object.freeze(this._actualState);
    }

    public Get(): Readonly<S> {
        return Object.freeze(this._actualState);
    }

    public static ParseUrl<S>(newState: S): S {
        const urlParams = new URLSearchParams(window.location.search);
        const skipKeys: string[] = [];

        urlParams.forEach((value, name) => {
            if (!skipKeys.includes(name)) {
                if (name.endsWith('[]')) {
                    let propertyName = name.slice(0, name.length - 2);
                    let newArray: string[] = [...urlParams.getAll(name)];
                    newState = { ...newState, [propertyName]: newArray };
                    skipKeys.push(name);
                } else if (name.endsWith('~')) {
                    newState = { ...newState, [name.slice(0, name.length - 1)]: +value };
                } else {
                    newState = { ...newState, [name]: value };
                }
            }
        });

        return newState;
    }

    public static GenerateUrlParams<S>(nextState: Partial<S>): URLSearchParams {
        const urlParams = new URLSearchParams(window.location.search);
        for (const part in nextState) {
            const value = nextState[part];
            if (!!value || String(value) === '') {
                if (value instanceof Array) {
                    urlParams.delete(part + '[]');
                    value.forEach((item) => {
                        urlParams.append(part + '[]', item);
                    });
                } else if (typeof (value) === 'number') {
                    urlParams.set(part + '~', String(value));
                } else {
                    urlParams.set(part, String(value));
                }
            }
        }
        return urlParams;
    }
}

(window.onpopstate = (): void => {
    void UrlState.OnPopState.invokeAsync(window.location.search);
})();

export function useUrlState<S extends {}>(initialState: S): [S, (value: Partial<S>) => void] {
    const [state, rawSetState] = useState(() => {
        UrlState.OnPopState.add(async (): Promise<void> => {
            let newState = rebuildState(window.location.search);
            rawSetState(() => {
                return { ...newState };
            });
        });
        return rebuildState(window.location.search, initialState);
    });

    function rebuildState(search: string, prevState?: S): S {
        let newState = !!prevState ? { ...prevState } : (initialState as S);
        newState = UrlState.ParseUrl(newState);
        return newState;
    }

    function setState(updatedState: Partial<S>): void {
        const nextState = { ...updatedState };
        const urlParams = UrlState.GenerateUrlParams(nextState);
        rawSetState((s) => {
            return { ...s, ...nextState };
        });
        goTo(window.location.pathname, urlParams.toString());
    }

    return [state, setState];
}
