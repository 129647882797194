import globalAxios, { AxiosError } from 'axios';
import { ValidationProblemDetails } from './Api';
import { AlertType } from './Components/AlertBar';
import { BadResponse } from './Models/Common/BadResponse';
import { AuthService } from './Services/authService';
import { alertStore } from './Services/serviceRegistration';

const setupGlobalAxios = (handleErrorsGlobally: boolean = false) => {
    globalAxios.interceptors.response.use(function (response) {
        return response;
    }, function (error: AxiosError<ValidationProblemDetails>) {
        const unknownErrorDescription = 'Произошла неизвестная ошибка. Обратитесь в техническую поддерку, если это событие повторится.';
        if (error === undefined || error.response === undefined) {
            alertStore.show(unknownErrorDescription, AlertType.Error);
            return Promise.reject(error);
        }
        if (handleErrorsGlobally) {
            if (error.response.status === 500) {
                alertStore.show('Сервер недоступен. Проверьте подключение к сети.', AlertType.Error);
                return Promise.reject(error);
            }
            if (error.response.status === 401) {
                localStorage.removeItem(AuthService.jwtTokenKey);
            }
            const data = new BadResponse(error.response.data);
            alertStore.show(data.firstError ?? unknownErrorDescription, AlertType.Error);
        } else {
            if (error.response.status < 500) {
                return;
            }
        }
        return Promise.reject(error);
    });
};

export const configureApp = () => {
    setupGlobalAxios(true);
};