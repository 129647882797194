import { UserRole } from '../../Api';

export const AuthorizedUserRoles: UserRole[] = [
    UserRole.Admin,
    UserRole.Viewer,
    UserRole.FactorySupervisor,
];

export const toRussian = (status: UserRole) => {
    const data = {
        [UserRole.Admin]: 'Администратор',
        [UserRole.Viewer]: 'Пользователь',
        [UserRole.Unauthorized]: 'Пользователь (без почты)',
        [UserRole.FactorySupervisor]: 'Куратор фабрики',
    };
    return data[status];
};