import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { PermissionSupport } from '../../Components/PermissionSupport';
import { UserRole } from '../../Api';
import {
    Card,
    CardContent,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import { useStores } from '../../Services/serviceRegistration';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import { useGameManagementStyles } from './GameManagementStyles';
import { Dropdown } from '../../Components/Dropdown/Dropdown';
import { Header } from '../../Components/Header/Header';
import { runInAction } from 'mobx';
import { Autocomplete } from '@material-ui/lab';
import { AuthorizedUserRoles, toRussian } from '../../Models/Profile/UserRole';

export const PlayerManagementPage: React.FC = observer(() => {
    const { gameAdminStore } = useStores();
    useEffect(() => {
        gameAdminStore.switchPage(gameAdminStore.pageList[1]);
    }, []);
    const classes = useGameManagementStyles();

    return (
        <Container>
            <PermissionSupport allow={[UserRole.Admin]}>
                <Grid container spacing={2} style={{ marginTop: '1rem' }} alignContent={'stretch'}>
                    <Grid item md={4} xs={12}>
                        <Card>
                            <CardContent
                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Dropdown
                                    render={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    renderOption={(option) => (
                                        <Typography gutterBottom variant="h5" style={{ margin: 'auto 0' }}>
                                            {option.title}
                                        </Typography>
                                    )}
                                    options={gameAdminStore.pageList}
                                    value={gameAdminStore.selectedPage}
                                    onChange={(option) => {
                                        gameAdminStore.switchPage(option);
                                    }}
                                />
                            </CardContent>
                            <List className={classes.leftColumn}>
                                {gameAdminStore.users.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary="В системе нет игроков"
                                            secondary="Пригласите участников для начала работы"
                                        />
                                    </ListItem>
                                )}
                                {gameAdminStore.users.map((player) => (
                                    <ListItem
                                        button
                                        key={player.id}
                                        selected={
                                            !!gameAdminStore.selectedUser &&
                                            gameAdminStore.selectedUser.id === player.id
                                        }
                                        onClick={() => {
                                            runInAction(() => (gameAdminStore.selectedUser = player));
                                        }}
                                    >
                                        <ListItemText
                                            primary={`${player.lastName} ${player.firstName}`}
                                            secondary={`${
                                                isNullOrUndefined(player.role)
                                                    ? 'Неизвестная роль'
                                                    : toRussian(player.role)
                                            }`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                    <Grid item md={8} xs={12} className={classes.inheritHeight}>
                        {isNullOrUndefined(gameAdminStore.selectedUser) ? (
                            <Card className={classes.emptyRightColumn}>
                                <Typography variant={'h5'}>Выберите участника</Typography>
                            </Card>
                        ) : (
                            <Card className={`${classes.userManagementRightColumn}`}>
                                <Header
                                    title={'Просмотр участника'}
                                    buttonTitle={'Сохранить'}
                                    onButtonClick={() => {
                                        if (isNullOrUndefined(gameAdminStore.selectedUser)) {
                                            return;
                                        }
                                        void gameAdminStore.updateUser(gameAdminStore.selectedUser);
                                    }}
                                    isButtonDisabled={
                                        gameAdminStore.isBusy ||
                                        (gameAdminStore.isSaved(gameAdminStore.selectedUser?.id, 'role') &&
                                            gameAdminStore.isSaved(gameAdminStore.selectedUser?.id, 'adminCommentary'))
                                    }
                                />
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        id="user-full-name"
                                        label="ФИО"
                                        value={`${gameAdminStore.selectedUser.lastName} ${gameAdminStore.selectedUser.firstName} ${gameAdminStore.selectedUser.patronymic}`}
                                    />
                                </CardContent>
                                <CardContent>
                                    <Autocomplete
                                        disablePortal
                                        id="user-role"
                                        options={AuthorizedUserRoles}
                                        value={gameAdminStore.selectedUser.role}
                                        getOptionLabel={(option) => toRussian(option)}
                                        getOptionSelected={(option, value) => option === value}
                                        onChange={(event, value) => {
                                            runInAction(() => {
                                                if (
                                                    isNotNullOrUndefined(value) &&
                                                    isNotNullOrUndefined(gameAdminStore.selectedUser)
                                                ) {
                                                    gameAdminStore.selectedUser.role = value;
                                                    gameAdminStore.setSaveStatus(
                                                        gameAdminStore.selectedUser?.id,
                                                        false,
                                                        'role',
                                                    );
                                                }
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField variant={'outlined'} {...params} label="Глобальная роль" />
                                        )}
                                    />
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        required
                                        id="user-admin-commentary"
                                        label="Комментарий админа"
                                        value={gameAdminStore.selectedUser.adminCommentary || ''}
                                        onChange={(e) => {
                                            runInAction(
                                                () =>
                                                    (gameAdminStore.selectedUser!.adminCommentary =
                                                        e.currentTarget.value),
                                            );
                                            gameAdminStore.setSaveStatus(
                                                gameAdminStore.selectedUser?.id,
                                                false,
                                                'adminCommentary',
                                            );
                                        }}
                                    />
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        id="user-email"
                                        label="Email"
                                        value={gameAdminStore.selectedUser.userEmail}
                                    />
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        id="user-phone-number"
                                        label="Телефон"
                                        value={gameAdminStore.selectedUser.phoneNumber}
                                    />
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        id="user-luggage"
                                        label="Багаж"
                                        multiline
                                        rows={6}
                                        value={gameAdminStore.selectedUser.luggage || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        variant={'outlined'}
                                        id="user-experience"
                                        label="Опыт"
                                        multiline
                                        rows={6}
                                        value={gameAdminStore.selectedUser.experience || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </CardContent>
                                {gameAdminStore.selectedUser.education?.map((stage, index) => (
                                    <CardContent key={index}>
                                        <Paper variant={'outlined'} style={{ padding: '1rem' }}>
                                            <Grid container spacing={2}>
                                                <Typography variant={'body1'}>{`Образование ${index + 1}:`}</Typography>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant={'outlined'}
                                                        id={`user-education-institution-${index}`}
                                                        label="Название учебного заведения"
                                                        value={stage.institution}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant={'outlined'}
                                                        id={`user-education-field-of-study-${index}`}
                                                        label="Специальность"
                                                        value={stage.fieldOfStudy}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant={'outlined'}
                                                        id={`user-education-type-of-degree-${index}`}
                                                        label="Степень"
                                                        value={stage.typeOfDegree}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        variant={'outlined'}
                                                        id={`user-education-start-date-${index}`}
                                                        label="Начало"
                                                        type="date"
                                                        value={stage.startDate}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <TextField
                                                        variant={'outlined'}
                                                        id={`user-education-end-date-${index}`}
                                                        label="Окончание"
                                                        type="date"
                                                        value={stage.endDate}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant={'outlined'}
                                                        id={`user-education-summary-${index}`}
                                                        label="Описание"
                                                        multiline
                                                        rows={4}
                                                        value={stage.summary}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </CardContent>
                                ))}
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </PermissionSupport>
        </Container>
    );
});
