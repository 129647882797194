import React from "react";
import {
    FormControl,
    CardActions,
    Button,
    CardContent,
    Card,
    Typography,
    TextField,
    Link,
    Box,
    LinearProgress
} from "@material-ui/core";
import { useActions } from "./actions";
import { useStyles } from "../LoginPage/styles";

export default function ChangeEmailPage() {
    const classes = useStyles();
    const { emailFromQuery, handleSubmit, isBusy, formState, setFormState } = useActions();

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Card raised>
                    <LinearProgress hidden={!isBusy} />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Изменение почтового ящика
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            На вашу новую электронную почту придет письмо, 
                            по ссылке в котором необходимо будет подтвердить, 
                            что ваша новая почта действительно принадлежит вам.
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <div>
                            <FormControl>
                                <TextField
                                    disabled
                                    error={!!formState.emailError}
                                    helperText={formState.emailError}
                                    label="Новая электронная почта"
                                    variant="outlined"
                                    defaultValue={emailFromQuery}
                                    onChange={e =>
                                        setFormState({
                                            ...formState,
                                            email: e.target.value
                                        })
                                    }
                                />
                            </FormControl>
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={handleSubmit}
                            disabled={isBusy}
                            className={classes.submitButton}
                            variant="contained"
                            color="primary"
                        >
                            Подтвердить
                        </Button>
                    </CardActions>
                </Card>
            </form>
            <Typography component="div" className={classes.restoreLink}>
                <Box fontSize={"0.8rem"}>
                    <Link href="https://akmetron.ru/">АО Акметрон</Link>
                </Box>
            </Typography>
        </div>
    );
}
