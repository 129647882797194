import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            flexGrow: 1
        },
        titleTypography: {
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
                textDecoration: "underline",
            }
        }
    })
);
