import { observer } from 'mobx-react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Chip,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { getCoinGameStyles } from './GetCoinGameStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddFpiContributionDialog } from './AddFpiContributionDialog';
import { Header } from '../../Components/Header/Header';
import { CardRow } from '../../Components/CardRow/CardRow';
import { historyService, useStores } from '../../Services/serviceRegistration';
import { toRussian } from '../../Models/Profile/UserRole';
import { isNotNullOrUndefined } from '../../Helpers/utils';
import MaterialTable, { Column } from 'material-table';
import { PlayerDto, ProjectDto } from '../../Api';
import { projectRoleToRussian } from '../../Models/FpiGame/ProjectRoleEnumHelper';
import { Coin } from '../../Components/Coin/Coin';

interface IPlayerDashboardPageState {
    isAddFpiContributionDialogVisible: boolean;
}

interface IPlayerStatisticRow {
    project: ProjectDto;
    player: PlayerDto;
}

export const PlayerDashboardPage: React.FC = observer(() => {
    const classes = getCoinGameStyles();
    const { fpiGameStore, userStore } = useStores();
    const [state, setState] = useState<IPlayerDashboardPageState>({ isAddFpiContributionDialogVisible: false });

    const columns: Column<IPlayerStatisticRow>[] = useMemo(() => {
        return [
            {
                title: 'ФИО',
                render: (data) => <Typography>{`${data.player.lastName} ${data.player.firstName}`}</Typography>,
            },
            {
                title: 'Роль',
                render: (data) => (
                    <Typography>
                        {data.player.id === data.project?.creator?.id
                            ? 'Создатель'
                            : projectRoleToRussian(data.player.projectRole)}
                    </Typography>
                ),
            },
            {
                title: 'Токены',
                render: (data) => {
                    const value = fpiGameStore.getPlayerRate(data.player, data.project);
                    return <Typography>{+(value?.toFixed(2))}</Typography>;
                },
            },
        ];
    }, [fpiGameStore.projects]);

    if (userStore.currentUser === undefined) {
        return <></>;
    }
    return (
        <Container className={classes.content} style={{ marginTop: '1rem' }}>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Card className={classes.column}>
                        <Header
                            title={'Профиль участника'}
                            description={'Личная информация участника программы "Фабрика перспективных идей"'}
                        />
                        <CardRow
                            label="Вы"
                            title={`${userStore.currentUser.lastName} ${userStore.currentUser.firstName}`}
                            description={toRussian(userStore.currentUser.role)}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card className={classes.column}>
                        <Header title={'Идеи'} description={'Список идей, к которым у вас есть доступ'} />
                        <CardContent className={classes.content}>
                            {fpiGameStore.projects.length === 0 && (
                                <Typography variant="body2" color="textSecondary" component="p">
                                    На данный момент вы не участвуете ни в одном проекте
                                </Typography>
                            )}
                            {fpiGameStore.projectRating.map(({ project, tokenNumber }) => {
                                return (
                                    <Accordion style={{ margin: '0px 5px' }} key={project.id}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Coin totalRate={tokenNumber} />
                                                    <div>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {project.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            {project.description}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div>
                                                    {project.attachments?.map((attachment) => (
                                                        <Chip
                                                            style={{ margin: '2.5px' }}
                                                            label={attachment.description}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (isNotNullOrUndefined(attachment.url)) {
                                                                    historyService.goToExternal(attachment.url);
                                                                }
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid
                                                container
                                                justifyContent={'space-between'}
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Grid item xs={12} className={classes.content}>
                                                    <MaterialTable
                                                        style={{ padding: 0 }}
                                                        columns={columns}
                                                        data={[
                                                            ...(project.contributors?.map((player) => ({
                                                                project,
                                                                player,
                                                            })) ?? []),
                                                            {
                                                                project,
                                                                player: {
                                                                    id: undefined,
                                                                    lastName: 'Фабрика',
                                                                    firstName: '',
                                                                    projectRole: undefined,
                                                                },
                                                            },
                                                        ]}
                                                        isLoading={false}
                                                        options={{
                                                            search: false,
                                                            paging: false,
                                                            columnsButton: false,
                                                            sorting: false,
                                                            draggable: false,
                                                            showTitle: false,
                                                            toolbar: false,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <AddFpiContributionDialog
                userId={userStore.currentUser.id}
                isVisible={state.isAddFpiContributionDialogVisible}
                onDialogClose={() => setState((s) => ({ ...s, isAddFpiContributionDialogVisible: false }))}
            />
        </Container>
    );
});
