import { useState, useEffect } from "react";
import { validateValue } from "../../Helpers/validationSupport";

export interface IChangeUsernameDialogProps {
    username: string;
    usernameError?: string;
    onSuccess?: (email: string) => Promise<boolean>;
    onCancel?: () => Promise<boolean>;
    isVisible: boolean;
}

export function useActions(props: IChangeUsernameDialogProps) {
    const [state, setState] = useState<IChangeUsernameDialogProps>(props);
    const [isBusy, setIsBusy] = useState(false);

    const handleClose = async () => {
        setIsBusy(true);
        try {
            const result = await state.onCancel?.call(state);
            setState(v => ({ ...v, isVisible: !!result }));
        } finally {
            setIsBusy(false);
        }
    };
    
    const validateUsername = () => validateValue(state.username)
        .ensureNonEmpty()        
        .getLastError();

    const handleSuccess = async () => {
        if (!!validateUsername()) {
            return;
        }
        setIsBusy(true);
        try {
            const result = await state.onSuccess?.call(
                state,
                state.username
            );
            setState(v => ({ ...v, isVisible: !!result }));
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {        
        setState(v => ({ ...v, usernameError: validateUsername() }));

    }, [state.username]);

    useEffect(() => {
        setState(props);
    }, [props]);

    return { isBusy, state, setState, handleClose, handleSuccess };
}
