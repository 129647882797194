/* tslint:disable */
/* eslint-disable */
/**
 * Fpi Game Api
 * Akmetron Fpi Game Api
 *
 * The version of the OpenAPI document: v1
 * Contact: support@akmetron.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAttachmentToProjectInput
 */
export interface AddAttachmentToProjectInput {
    /**
     * 
     * @type {string}
     * @memberof AddAttachmentToProjectInput
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddAttachmentToProjectInput
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface AddContributorToProjectInput
 */
export interface AddContributorToProjectInput {
    /**
     * 
     * @type {string}
     * @memberof AddContributorToProjectInput
     */
    contributorId: string;
    /**
     * 
     * @type {FpiRole}
     * @memberof AddContributorToProjectInput
     */
    role: FpiRole;
}
/**
 * 
 * @export
 * @interface ArchiveUserViewModel
 */
export interface ArchiveUserViewModel {
    /**
     * 
     * @type {string}
     * @memberof ArchiveUserViewModel
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveUserViewModel
     */
    getKey?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface ChangeEmailViewModel
 */
export interface ChangeEmailViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailViewModel
     */
    newEmail: string;
}
/**
 * 
 * @export
 * @interface ChangeUserNameViewModel
 */
export interface ChangeUserNameViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserNameViewModel
     */
    newUserName: string;
}
/**
 * 
 * @export
 * @interface ChangeUserPhoneNumberViewModel
 */
export interface ChangeUserPhoneNumberViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserPhoneNumberViewModel
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface ChangeUserProfileViewModel
 */
export interface ChangeUserProfileViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileViewModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileViewModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileViewModel
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileViewModel
     */
    companyName?: string | null;
}
/**
 * 
 * @export
 * @interface ChangeUserRoleViewModel
 */
export interface ChangeUserRoleViewModel {
    /**
     * 
     * @type {UserRole}
     * @memberof ChangeUserRoleViewModel
     */
    userRole: UserRole;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserRoleViewModel
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserRoleViewModel
     */
    getKey?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ConfirmChangeEmailViewModel
 */
export interface ConfirmChangeEmailViewModel {
    /**
     * 
     * @type {string}
     * @memberof ConfirmChangeEmailViewModel
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmChangeEmailViewModel
     */
    newEmail: string;
}
/**
 * 
 * @export
 * @interface ConfirmEmailViewModel
 */
export interface ConfirmEmailViewModel {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailViewModel
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailViewModel
     */
    getKey?: string | null;
}
/**
 * 
 * @export
 * @interface ContributionDto
 */
export interface ContributionDto {
    /**
     * 
     * @type {PlayerDto}
     * @memberof ContributionDto
     */
    player?: PlayerDto | null;
    /**
     * 
     * @type {number}
     * @memberof ContributionDto
     */
    reward?: number;
    /**
     * 
     * @type {string}
     * @memberof ContributionDto
     */
    lastActivity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContributionDto
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface EducationStageDto
 */
export interface EducationStageDto {
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    institution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    fieldOfStudy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    typeOfDegree?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationStageDto
     */
    summary?: string | null;
}
/**
 * 
 * @export
 * @interface ForgotPasswordViewModel
 */
export interface ForgotPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordViewModel
     */
    getKey?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FpiRole {
    Ideologist = 'Ideologist',
    FactorySupervisor = 'FactorySupervisor',
    ProductSpecialist = 'ProductSpecialist',
    Engineer = 'Engineer',
    Expert = 'Expert'
}

/**
 * 
 * @export
 * @interface LoginResult
 */
export interface LoginResult {
    /**
     * 
     * @type {string}
     * @memberof LoginResult
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface LoginViewModel
 */
export interface LoginViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface NewAttachmentResult
 */
export interface NewAttachmentResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof NewAttachmentResult
     */
    project?: ProjectDto | null;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof NewAttachmentResult
     */
    attachment?: AttachmentDto | null;
}
/**
 * 
 * @export
 * @interface NewContributionInput
 */
export interface NewContributionInput {
    /**
     * 
     * @type {string}
     * @memberof NewContributionInput
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof NewContributionInput
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof NewContributionInput
     */
    reward: number;
}
/**
 * 
 * @export
 * @interface NewContributionResult
 */
export interface NewContributionResult {
    /**
     * 
     * @type {ContributionDto}
     * @memberof NewContributionResult
     */
    result?: ContributionDto | null;
}
/**
 * 
 * @export
 * @interface NewProjectInput
 */
export interface NewProjectInput {
    /**
     * 
     * @type {string}
     * @memberof NewProjectInput
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInput
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface NewProjectResult
 */
export interface NewProjectResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof NewProjectResult
     */
    result?: ProjectDto | null;
}
/**
 * 
 * @export
 * @interface PlayerDto
 */
export interface PlayerDto {
    /**
     * 
     * @type {UserRole}
     * @memberof PlayerDto
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    patronymic?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof PlayerDto
     */
    userCompany?: CompanyDto | null;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {FpiRole}
     * @memberof PlayerDto
     */
    projectRole?: FpiRole;
    /**
     * 
     * @type {string}
     * @memberof PlayerDto
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: object | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    modifier?: number;
    /**
     * 
     * @type {PlayerDto}
     * @memberof ProjectDto
     */
    creator?: PlayerDto | null;
    /**
     * 
     * @type {Array<PlayerDto>}
     * @memberof ProjectDto
     */
    contributors?: Array<PlayerDto> | null;
    /**
     * 
     * @type {Array<ContributionDto>}
     * @memberof ProjectDto
     */
    records?: Array<ContributionDto> | null;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof ProjectDto
     */
    attachments?: Array<AttachmentDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterViewModel
 */
export interface RegisterViewModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface RemoveAttachmentFromProjectResult
 */
export interface RemoveAttachmentFromProjectResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof RemoveAttachmentFromProjectResult
     */
    project?: ProjectDto | null;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof RemoveAttachmentFromProjectResult
     */
    removedElement?: AttachmentDto | null;
}
/**
 * 
 * @export
 * @interface RemoveContributorFromProjectResult
 */
export interface RemoveContributorFromProjectResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof RemoveContributorFromProjectResult
     */
    result?: ProjectDto | null;
}
/**
 * 
 * @export
 * @interface ResetPasswordViewModel
 */
export interface ResetPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    getKey?: string | null;
}
/**
 * 
 * @export
 * @interface StatusCodeResult
 */
export interface StatusCodeResult {
    /**
     * 
     * @type {number}
     * @memberof StatusCodeResult
     */
    statusCode?: number;
}
/**
 * 
 * @export
 * @interface StatusCodeResultValidationProblemDetailsEither
 */
export interface StatusCodeResultValidationProblemDetailsEither {
    /**
     * 
     * @type {ValidationProblemDetails}
     * @memberof StatusCodeResultValidationProblemDetailsEither
     */
    right?: ValidationProblemDetails | null;
    /**
     * 
     * @type {StatusCodeResult}
     * @memberof StatusCodeResultValidationProblemDetailsEither
     */
    left?: StatusCodeResult | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatusCodeResultValidationProblemDetailsEither
     */
    isRight?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatusCodeResultValidationProblemDetailsEither
     */
    isLeft?: boolean;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    tokenValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    expireTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface UnauthorizeChangeEmailViewModel
 */
export interface UnauthorizeChangeEmailViewModel {
    /**
     * 
     * @type {string}
     * @memberof UnauthorizeChangeEmailViewModel
     */
    newEmail: string;
}
/**
 * 
 * @export
 * @interface UpdateContributionInput
 */
export interface UpdateContributionInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateContributionInput
     */
    projectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateContributionInput
     */
    userId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateContributionInput
     */
    reward?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateContributionResult
 */
export interface UpdateContributionResult {
    /**
     * 
     * @type {ContributionDto}
     * @memberof UpdateContributionResult
     */
    result?: ContributionDto | null;
}
/**
 * 
 * @export
 * @interface UpdateContributorInput
 */
export interface UpdateContributorInput {
    /**
     * 
     * @type {FpiRole}
     * @memberof UpdateContributorInput
     */
    projectRole?: FpiRole | null;
}
/**
 * 
 * @export
 * @interface UpdateContributorResult
 */
export interface UpdateContributorResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof UpdateContributorResult
     */
    result?: ProjectDto | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectInput
 */
export interface UpdateProjectInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInput
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInput
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectResult
 */
export interface UpdateProjectResult {
    /**
     * 
     * @type {ProjectDto}
     * @memberof UpdateProjectResult
     */
    result?: ProjectDto | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectRewardsInput
 */
export interface UpdateProjectRewardsInput {
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRewardsInput
     */
    modifier: number;
}
/**
 * 
 * @export
 * @interface UpdateUserById
 */
export interface UpdateUserById {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserById
     */
    adminCommentary?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserInput
 */
export interface UpdateUserInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInput
     */
    experience?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserInput
     */
    luggage?: string | null;
    /**
     * 
     * @type {Array<EducationStageDto>}
     * @memberof UpdateUserInput
     */
    education?: Array<EducationStageDto> | null;
}
/**
 * 
 * @export
 * @interface UpdateUserResult
 */
export interface UpdateUserResult {
    /**
     * 
     * @type {UserDto}
     * @memberof UpdateUserResult
     */
    result?: UserDto | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {UserRole}
     * @memberof UserDto
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    userEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isTwoFactorEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isArchived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    patronymic?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof UserDto
     */
    userCompany?: CompanyDto | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    experience?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    luggage?: string | null;
    /**
     * 
     * @type {Array<EducationStageDto>}
     * @memberof UserDto
     */
    education?: Array<EducationStageDto> | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    adminCommentary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserRole {
    Admin = 'Admin',
    Viewer = 'Viewer',
    Unauthorized = 'Unauthorized',
    FactorySupervisor = 'FactorySupervisor'
}

/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: object | any;

    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    errors?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    instance?: string | null;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Подтверждение регистрации аккаунта
         * @param {ConfirmEmailViewModel} [confirmEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (confirmEmailViewModel?: ConfirmEmailViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Формирование ссылки для сброса пароля
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordViewModel?: ForgotPasswordViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вход в систему
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginViewModel?: LoginViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выход из системы, текущий токен будет считаться неактивным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация нового пользователя (роль по умолчанию: Unauthorized)  Подтверждение аккаунта будет выслано на почту
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerViewModel?: RegisterViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordViewModel?: ResetPasswordViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Послать ссылку на почту для продолжения регистрации аккаунта
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailConfirmationLink: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/SendEmailConfirmationLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение email без дополнительного подтверждения по почте
         * @param {UnauthorizeChangeEmailViewModel} [unauthorizeChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthorizeChangeEmail: async (unauthorizeChangeEmailViewModel?: UnauthorizeChangeEmailViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/UnauthorizeChangeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unauthorizeChangeEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Валидация токена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/VerifyToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Подтверждение регистрации аккаунта
         * @param {ConfirmEmailViewModel} [confirmEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(confirmEmailViewModel?: ConfirmEmailViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(confirmEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Формирование ссылки для сброса пароля
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вход в систему
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginViewModel?: LoginViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выход из системы, текущий токен будет считаться неактивным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Регистрация нового пользователя (роль по умолчанию: Unauthorized)  Подтверждение аккаунта будет выслано на почту
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerViewModel?: RegisterViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusCodeResultValidationProblemDetailsEither>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordViewModel?: ResetPasswordViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Послать ссылку на почту для продолжения регистрации аккаунта
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailConfirmationLink(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailConfirmationLink(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение email без дополнительного подтверждения по почте
         * @param {UnauthorizeChangeEmailViewModel} [unauthorizeChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unauthorizeChangeEmail(unauthorizeChangeEmailViewModel?: UnauthorizeChangeEmailViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unauthorizeChangeEmail(unauthorizeChangeEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Валидация токена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Подтверждение регистрации аккаунта
         * @param {ConfirmEmailViewModel} [confirmEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(confirmEmailViewModel?: ConfirmEmailViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.confirmEmail(confirmEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Формирование ссылки для сброса пароля
         * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.forgotPassword(forgotPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вход в систему
         * @param {LoginViewModel} [loginViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginViewModel?: LoginViewModel, options?: any): AxiosPromise<LoginResult> {
            return localVarFp.login(loginViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выход из системы, текущий токен будет считаться неактивным
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация нового пользователя (роль по умолчанию: Unauthorized)  Подтверждение аккаунта будет выслано на почту
         * @param {RegisterViewModel} [registerViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerViewModel?: RegisterViewModel, options?: any): AxiosPromise<StatusCodeResultValidationProblemDetailsEither> {
            return localVarFp.register(registerViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сброс пароля
         * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordViewModel?: ResetPasswordViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Послать ссылку на почту для продолжения регистрации аккаунта
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailConfirmationLink(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailConfirmationLink(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение email без дополнительного подтверждения по почте
         * @param {UnauthorizeChangeEmailViewModel} [unauthorizeChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthorizeChangeEmail(unauthorizeChangeEmailViewModel?: UnauthorizeChangeEmailViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.unauthorizeChangeEmail(unauthorizeChangeEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Валидация токена
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(options?: any): AxiosPromise<void> {
            return localVarFp.verifyToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Подтверждение регистрации аккаунта
     * @param {ConfirmEmailViewModel} [confirmEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmEmail(confirmEmailViewModel?: ConfirmEmailViewModel, options?: any) {
        return AuthApiFp(this.configuration).confirmEmail(confirmEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Формирование ссылки для сброса пароля
     * @param {ForgotPasswordViewModel} [forgotPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(forgotPasswordViewModel?: ForgotPasswordViewModel, options?: any) {
        return AuthApiFp(this.configuration).forgotPassword(forgotPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вход в систему
     * @param {LoginViewModel} [loginViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginViewModel?: LoginViewModel, options?: any) {
        return AuthApiFp(this.configuration).login(loginViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выход из системы, текущий токен будет считаться неактивным
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация нового пользователя (роль по умолчанию: Unauthorized)  Подтверждение аккаунта будет выслано на почту
     * @param {RegisterViewModel} [registerViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(registerViewModel?: RegisterViewModel, options?: any) {
        return AuthApiFp(this.configuration).register(registerViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сброс пароля
     * @param {ResetPasswordViewModel} [resetPasswordViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPasswordViewModel?: ResetPasswordViewModel, options?: any) {
        return AuthApiFp(this.configuration).resetPassword(resetPasswordViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Послать ссылку на почту для продолжения регистрации аккаунта
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendEmailConfirmationLink(body?: object, options?: any) {
        return AuthApiFp(this.configuration).sendEmailConfirmationLink(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение email без дополнительного подтверждения по почте
     * @param {UnauthorizeChangeEmailViewModel} [unauthorizeChangeEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public unauthorizeChangeEmail(unauthorizeChangeEmailViewModel?: UnauthorizeChangeEmailViewModel, options?: any) {
        return AuthApiFp(this.configuration).unauthorizeChangeEmail(unauthorizeChangeEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Валидация токена
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyToken(options?: any) {
        return AuthApiFp(this.configuration).verifyToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContributionApi - axios parameter creator
 * @export
 */
export const ContributionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать новый вклад для указанного проекта.
         * @param {NewContributionInput} [newContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContribution: async (newContributionInput?: NewContributionInput, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contributions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newContributionInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить выбранный вклад по идентификационному номеру.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContribution: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContribution', 'id', id)
            const localVarPath = `/api/contributions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить вклад по идентификационному номеру.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributionById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContributionById', 'id', id)
            const localVarPath = `/api/contributions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить все собственные вклады по всем проектам.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contributions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить вклад по выбранному идентификационному номеру.
         * @param {string} id 
         * @param {UpdateContributionInput} [updateContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContribution: async (id: string, updateContributionInput?: UpdateContributionInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContribution', 'id', id)
            const localVarPath = `/api/contributions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContributionInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContributionApi - functional programming interface
 * @export
 */
export const ContributionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContributionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать новый вклад для указанного проекта.
         * @param {NewContributionInput} [newContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContribution(newContributionInput?: NewContributionInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewContributionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContribution(newContributionInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить выбранный вклад по идентификационному номеру.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContribution(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContribution(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить вклад по идентификационному номеру.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContributionById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContributionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContributionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить все собственные вклады по всем проектам.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContributions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContributionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContributions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить вклад по выбранному идентификационному номеру.
         * @param {string} id 
         * @param {UpdateContributionInput} [updateContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContribution(id: string, updateContributionInput?: UpdateContributionInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateContributionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContribution(id, updateContributionInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContributionApi - factory interface
 * @export
 */
export const ContributionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContributionApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать новый вклад для указанного проекта.
         * @param {NewContributionInput} [newContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContribution(newContributionInput?: NewContributionInput, options?: any): AxiosPromise<NewContributionResult> {
            return localVarFp.createContribution(newContributionInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить выбранный вклад по идентификационному номеру.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContribution(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContribution(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить вклад по идентификационному номеру.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributionById(id: number, options?: any): AxiosPromise<ContributionDto> {
            return localVarFp.getContributionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить все собственные вклады по всем проектам.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContributions(options?: any): AxiosPromise<Array<ContributionDto>> {
            return localVarFp.getContributions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить вклад по выбранному идентификационному номеру.
         * @param {string} id 
         * @param {UpdateContributionInput} [updateContributionInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContribution(id: string, updateContributionInput?: UpdateContributionInput, options?: any): AxiosPromise<UpdateContributionResult> {
            return localVarFp.updateContribution(id, updateContributionInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContributionApi - object-oriented interface
 * @export
 * @class ContributionApi
 * @extends {BaseAPI}
 */
export class ContributionApi extends BaseAPI {
    /**
     * 
     * @summary Создать новый вклад для указанного проекта.
     * @param {NewContributionInput} [newContributionInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContributionApi
     */
    public createContribution(newContributionInput?: NewContributionInput, options?: any) {
        return ContributionApiFp(this.configuration).createContribution(newContributionInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить выбранный вклад по идентификационному номеру.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContributionApi
     */
    public deleteContribution(id: string, options?: any) {
        return ContributionApiFp(this.configuration).deleteContribution(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить вклад по идентификационному номеру.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContributionApi
     */
    public getContributionById(id: number, options?: any) {
        return ContributionApiFp(this.configuration).getContributionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить все собственные вклады по всем проектам.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContributionApi
     */
    public getContributions(options?: any) {
        return ContributionApiFp(this.configuration).getContributions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить вклад по выбранному идентификационному номеру.
     * @param {string} id 
     * @param {UpdateContributionInput} [updateContributionInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContributionApi
     */
    public updateContribution(id: string, updateContributionInput?: UpdateContributionInput, options?: any) {
        return ContributionApiFp(this.configuration).updateContribution(id, updateContributionInput, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить пользователя по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlayerById', 'id', id)
            const localVarPath = `/api/players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить всех доступных игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить пользователя по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить всех доступных игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlayerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayerApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить пользователя по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerById(id: string, options?: any): AxiosPromise<PlayerDto> {
            return localVarFp.getPlayerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить всех доступных игроков.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers(options?: any): AxiosPromise<Array<PlayerDto>> {
            return localVarFp.getPlayers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
    /**
     * 
     * @summary Получить пользователя по уникальному идентификатору.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayerById(id: string, options?: any) {
        return PlayerApiFp(this.configuration).getPlayerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить всех доступных игроков.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayers(options?: any) {
        return PlayerApiFp(this.configuration).getPlayers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Приоставить доступ к учетной записи, архивируя ее
         * @param {ArchiveUserViewModel} [archiveUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveUser: async (archiveUserViewModel?: ArchiveUserViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ArchiveUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveUserViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/BlockUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Формирование ссылки для измененя email
         * @param {ChangeEmailViewModel} [changeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (changeEmailViewModel?: ChangeEmailViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ChangeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение имени пользователя
         * @param {ChangeUserNameViewModel} [changeUserNameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserName: async (changeUserNameViewModel?: ChangeUserNameViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ChangeUserName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserNameViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение номера телефона
         * @param {ChangeUserPhoneNumberViewModel} [changeUserPhoneNumberViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPhoneNumber: async (changeUserPhoneNumberViewModel?: ChangeUserPhoneNumberViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ChangeUserPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserPhoneNumberViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление данных
         * @param {ChangeUserProfileViewModel} [changeUserProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserProfile: async (changeUserProfileViewModel?: ChangeUserProfileViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ChangeUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserProfileViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить роль пользователя (Admin, Moderator)
         * @param {ChangeUserRoleViewModel} [changeUserRoleViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRole: async (changeUserRoleViewModel?: ChangeUserRoleViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ChangeUserRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserRoleViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение почты
         * @param {ConfirmChangeEmailViewModel} [confirmChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChangeEmail: async (confirmChangeEmailViewModel?: ConfirmChangeEmailViewModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/ConfirmChangeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmChangeEmailViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить профиль пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/GetProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список всех пользователей (Admin)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile/GetUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {UpdateUserInput} [updateUserInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserInput?: UpdateUserInput, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {string} id 
         * @param {UpdateUserById} [updateUserById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById: async (id: string, updateUserById?: UpdateUserById, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserById', 'id', id)
            const localVarPath = `/api/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserById, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Приоставить доступ к учетной записи, архивируя ее
         * @param {ArchiveUserViewModel} [archiveUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveUser(archiveUserViewModel?: ArchiveUserViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveUser(archiveUserViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Формирование ссылки для измененя email
         * @param {ChangeEmailViewModel} [changeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(changeEmailViewModel?: ChangeEmailViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(changeEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение имени пользователя
         * @param {ChangeUserNameViewModel} [changeUserNameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserName(changeUserNameViewModel?: ChangeUserNameViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserName(changeUserNameViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение номера телефона
         * @param {ChangeUserPhoneNumberViewModel} [changeUserPhoneNumberViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPhoneNumber(changeUserPhoneNumberViewModel?: ChangeUserPhoneNumberViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPhoneNumber(changeUserPhoneNumberViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновление данных
         * @param {ChangeUserProfileViewModel} [changeUserProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserProfile(changeUserProfileViewModel?: ChangeUserProfileViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserProfile(changeUserProfileViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить роль пользователя (Admin, Moderator)
         * @param {ChangeUserRoleViewModel} [changeUserRoleViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserRole(changeUserRoleViewModel?: ChangeUserRoleViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserRole(changeUserRoleViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменение почты
         * @param {ConfirmChangeEmailViewModel} [confirmChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmChangeEmail(confirmChangeEmailViewModel?: ConfirmChangeEmailViewModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmChangeEmail(confirmChangeEmailViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить профиль пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список всех пользователей (Admin)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {UpdateUserInput} [updateUserInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserInput?: UpdateUserInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {string} id 
         * @param {UpdateUserById} [updateUserById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserById(id: string, updateUserById?: UpdateUserById, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserById(id, updateUserById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Приоставить доступ к учетной записи, архивируя ее
         * @param {ArchiveUserViewModel} [archiveUserViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveUser(archiveUserViewModel?: ArchiveUserViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.archiveUser(archiveUserViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser(options?: any): AxiosPromise<void> {
            return localVarFp.blockUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Формирование ссылки для измененя email
         * @param {ChangeEmailViewModel} [changeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(changeEmailViewModel?: ChangeEmailViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.changeEmail(changeEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение имени пользователя
         * @param {ChangeUserNameViewModel} [changeUserNameViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserName(changeUserNameViewModel?: ChangeUserNameViewModel, options?: any): AxiosPromise<Token> {
            return localVarFp.changeUserName(changeUserNameViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение номера телефона
         * @param {ChangeUserPhoneNumberViewModel} [changeUserPhoneNumberViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPhoneNumber(changeUserPhoneNumberViewModel?: ChangeUserPhoneNumberViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserPhoneNumber(changeUserPhoneNumberViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление данных
         * @param {ChangeUserProfileViewModel} [changeUserProfileViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserProfile(changeUserProfileViewModel?: ChangeUserProfileViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserProfile(changeUserProfileViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить роль пользователя (Admin, Moderator)
         * @param {ChangeUserRoleViewModel} [changeUserRoleViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserRole(changeUserRoleViewModel?: ChangeUserRoleViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserRole(changeUserRoleViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение почты
         * @param {ConfirmChangeEmailViewModel} [confirmChangeEmailViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmChangeEmail(confirmChangeEmailViewModel?: ConfirmChangeEmailViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.confirmChangeEmail(confirmChangeEmailViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить профиль пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<UserDto> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список всех пользователей (Admin)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {UpdateUserInput} [updateUserInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserInput?: UpdateUserInput, options?: any): AxiosPromise<UpdateUserResult> {
            return localVarFp.updateUser(updateUserInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить пользовательский профиль.
         * @param {string} id 
         * @param {UpdateUserById} [updateUserById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById(id: string, updateUserById?: UpdateUserById, options?: any): AxiosPromise<UpdateUserResult> {
            return localVarFp.updateUserById(id, updateUserById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Приоставить доступ к учетной записи, архивируя ее
     * @param {ArchiveUserViewModel} [archiveUserViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public archiveUser(archiveUserViewModel?: ArchiveUserViewModel, options?: any) {
        return ProfileApiFp(this.configuration).archiveUser(archiveUserViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public blockUser(options?: any) {
        return ProfileApiFp(this.configuration).blockUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Формирование ссылки для измененя email
     * @param {ChangeEmailViewModel} [changeEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeEmail(changeEmailViewModel?: ChangeEmailViewModel, options?: any) {
        return ProfileApiFp(this.configuration).changeEmail(changeEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение имени пользователя
     * @param {ChangeUserNameViewModel} [changeUserNameViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeUserName(changeUserNameViewModel?: ChangeUserNameViewModel, options?: any) {
        return ProfileApiFp(this.configuration).changeUserName(changeUserNameViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение номера телефона
     * @param {ChangeUserPhoneNumberViewModel} [changeUserPhoneNumberViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeUserPhoneNumber(changeUserPhoneNumberViewModel?: ChangeUserPhoneNumberViewModel, options?: any) {
        return ProfileApiFp(this.configuration).changeUserPhoneNumber(changeUserPhoneNumberViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление данных
     * @param {ChangeUserProfileViewModel} [changeUserProfileViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeUserProfile(changeUserProfileViewModel?: ChangeUserProfileViewModel, options?: any) {
        return ProfileApiFp(this.configuration).changeUserProfile(changeUserProfileViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить роль пользователя (Admin, Moderator)
     * @param {ChangeUserRoleViewModel} [changeUserRoleViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public changeUserRole(changeUserRoleViewModel?: ChangeUserRoleViewModel, options?: any) {
        return ProfileApiFp(this.configuration).changeUserRole(changeUserRoleViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение почты
     * @param {ConfirmChangeEmailViewModel} [confirmChangeEmailViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public confirmChangeEmail(confirmChangeEmailViewModel?: ConfirmChangeEmailViewModel, options?: any) {
        return ProfileApiFp(this.configuration).confirmChangeEmail(confirmChangeEmailViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить профиль пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список всех пользователей (Admin)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getUsers(options?: any) {
        return ProfileApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить пользовательский профиль.
     * @param {UpdateUserInput} [updateUserInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateUser(updateUserInput?: UpdateUserInput, options?: any) {
        return ProfileApiFp(this.configuration).updateUser(updateUserInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить пользовательский профиль.
     * @param {string} id 
     * @param {UpdateUserById} [updateUserById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateUserById(id: string, updateUserById?: UpdateUserById, options?: any) {
        return ProfileApiFp(this.configuration).updateUserById(id, updateUserById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавить дополнительную информацию к проекту.
         * @param {string} id 
         * @param {AddAttachmentToProjectInput} [addAttachmentToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentToProject: async (id: string, addAttachmentToProjectInput?: AddAttachmentToProjectInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addAttachmentToProject', 'id', id)
            const localVarPath = `/api/projects/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAttachmentToProjectInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить нового участника в проект. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {AddContributorToProjectInput} [addContributorToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContributorToProject: async (id: string, addContributorToProjectInput?: AddContributorToProjectInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addContributorToProject', 'id', id)
            const localVarPath = `/api/projects/{id}/contributors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addContributorToProjectInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать новый проект. Данная операция доступна только для админов сервиса.
         * @param {NewProjectInput} [newProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (newProjectInput?: NewProjectInput, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProjectInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить дополнительную информацию, относящуюся к проекту.
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment: async (id: string, attachmentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAttachment', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('deleteAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/api/projects/{id}/attachments/{attachmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить участника из проекта и удалить весь присвоенный для него вклад.  Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContributor: async (id: string, contributorId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContributor', 'id', id)
            // verify required parameter 'contributorId' is not null or undefined
            assertParamExists('deleteContributor', 'contributorId', contributorId)
            const localVarPath = `/api/projects/{id}/contributors/{contributorId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contributorId"}}`, encodeURIComponent(String(contributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить проект без возможности восстановления. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProject', 'id', id)
            const localVarPath = `/api/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить проект по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectById', 'id', id)
            const localVarPath = `/api/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить все доступные проекты для пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Применить указанный модификатор ко всем вкладам в выбранном проекте.
         * @param {string} id 
         * @param {UpdateProjectRewardsInput} [updateProjectRewardsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyProjectRewards: async (id: string, updateProjectRewardsInput?: UpdateProjectRewardsInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyProjectRewards', 'id', id)
            const localVarPath = `/api/projects/{id}/applyModifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRewardsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменить свойства участника в проекте.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {UpdateContributorInput} [updateContributorInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContributor: async (id: string, contributorId: string, updateContributorInput?: UpdateContributorInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContributor', 'id', id)
            // verify required parameter 'contributorId' is not null or undefined
            assertParamExists('updateContributor', 'contributorId', contributorId)
            const localVarPath = `/api/projects/{id}/contributors/{contributorId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"contributorId"}}`, encodeURIComponent(String(contributorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateContributorInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить данные по проекту. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {UpdateProjectInput} [updateProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (id: string, updateProjectInput?: UpdateProjectInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProject', 'id', id)
            const localVarPath = `/api/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавить дополнительную информацию к проекту.
         * @param {string} id 
         * @param {AddAttachmentToProjectInput} [addAttachmentToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAttachmentToProject(id: string, addAttachmentToProjectInput?: AddAttachmentToProjectInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewAttachmentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAttachmentToProject(id, addAttachmentToProjectInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить нового участника в проект. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {AddContributorToProjectInput} [addContributorToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContributorToProject(id: string, addContributorToProjectInput?: AddContributorToProjectInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContributorToProject(id, addContributorToProjectInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать новый проект. Данная операция доступна только для админов сервиса.
         * @param {NewProjectInput} [newProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(newProjectInput?: NewProjectInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(newProjectInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить дополнительную информацию, относящуюся к проекту.
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachment(id: string, attachmentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveAttachmentFromProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachment(id, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить участника из проекта и удалить весь присвоенный для него вклад.  Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContributor(id: string, contributorId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveContributorFromProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContributor(id, contributorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить проект без возможности восстановления. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить проект по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить все доступные проекты для пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Применить указанный модификатор ко всем вкладам в выбранном проекте.
         * @param {string} id 
         * @param {UpdateProjectRewardsInput} [updateProjectRewardsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyProjectRewards(id: string, updateProjectRewardsInput?: UpdateProjectRewardsInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyProjectRewards(id, updateProjectRewardsInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Изменить свойства участника в проекте.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {UpdateContributorInput} [updateContributorInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContributor(id: string, contributorId: string, updateContributorInput?: UpdateContributorInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateContributorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContributor(id, contributorId, updateContributorInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить данные по проекту. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {UpdateProjectInput} [updateProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(id: string, updateProjectInput?: UpdateProjectInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(id, updateProjectInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавить дополнительную информацию к проекту.
         * @param {string} id 
         * @param {AddAttachmentToProjectInput} [addAttachmentToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttachmentToProject(id: string, addAttachmentToProjectInput?: AddAttachmentToProjectInput, options?: any): AxiosPromise<NewAttachmentResult> {
            return localVarFp.addAttachmentToProject(id, addAttachmentToProjectInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить нового участника в проект. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {AddContributorToProjectInput} [addContributorToProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContributorToProject(id: string, addContributorToProjectInput?: AddContributorToProjectInput, options?: any): AxiosPromise<NewProjectResult> {
            return localVarFp.addContributorToProject(id, addContributorToProjectInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать новый проект. Данная операция доступна только для админов сервиса.
         * @param {NewProjectInput} [newProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(newProjectInput?: NewProjectInput, options?: any): AxiosPromise<NewProjectResult> {
            return localVarFp.createProject(newProjectInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить дополнительную информацию, относящуюся к проекту.
         * @param {string} id 
         * @param {string} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment(id: string, attachmentId: string, options?: any): AxiosPromise<RemoveAttachmentFromProjectResult> {
            return localVarFp.deleteAttachment(id, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить участника из проекта и удалить весь присвоенный для него вклад.  Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContributor(id: string, contributorId: string, options?: any): AxiosPromise<RemoveContributorFromProjectResult> {
            return localVarFp.deleteContributor(id, contributorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить проект без возможности восстановления. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить проект по уникальному идентификатору.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id: string, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить все доступные проекты для пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Применить указанный модификатор ко всем вкладам в выбранном проекте.
         * @param {string} id 
         * @param {UpdateProjectRewardsInput} [updateProjectRewardsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyProjectRewards(id: string, updateProjectRewardsInput?: UpdateProjectRewardsInput, options?: any): AxiosPromise<UpdateProjectResult> {
            return localVarFp.modifyProjectRewards(id, updateProjectRewardsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменить свойства участника в проекте.
         * @param {string} id 
         * @param {string} contributorId 
         * @param {UpdateContributorInput} [updateContributorInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContributor(id: string, contributorId: string, updateContributorInput?: UpdateContributorInput, options?: any): AxiosPromise<UpdateContributorResult> {
            return localVarFp.updateContributor(id, contributorId, updateContributorInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить данные по проекту. Данная операция доступна только для админов сервиса.
         * @param {string} id 
         * @param {UpdateProjectInput} [updateProjectInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(id: string, updateProjectInput?: UpdateProjectInput, options?: any): AxiosPromise<UpdateProjectResult> {
            return localVarFp.updateProject(id, updateProjectInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Добавить дополнительную информацию к проекту.
     * @param {string} id 
     * @param {AddAttachmentToProjectInput} [addAttachmentToProjectInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addAttachmentToProject(id: string, addAttachmentToProjectInput?: AddAttachmentToProjectInput, options?: any) {
        return ProjectApiFp(this.configuration).addAttachmentToProject(id, addAttachmentToProjectInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить нового участника в проект. Данная операция доступна только для админов сервиса.
     * @param {string} id 
     * @param {AddContributorToProjectInput} [addContributorToProjectInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addContributorToProject(id: string, addContributorToProjectInput?: AddContributorToProjectInput, options?: any) {
        return ProjectApiFp(this.configuration).addContributorToProject(id, addContributorToProjectInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать новый проект. Данная операция доступна только для админов сервиса.
     * @param {NewProjectInput} [newProjectInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(newProjectInput?: NewProjectInput, options?: any) {
        return ProjectApiFp(this.configuration).createProject(newProjectInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить дополнительную информацию, относящуюся к проекту.
     * @param {string} id 
     * @param {string} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteAttachment(id: string, attachmentId: string, options?: any) {
        return ProjectApiFp(this.configuration).deleteAttachment(id, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить участника из проекта и удалить весь присвоенный для него вклад.  Данная операция доступна только для админов сервиса.
     * @param {string} id 
     * @param {string} contributorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteContributor(id: string, contributorId: string, options?: any) {
        return ProjectApiFp(this.configuration).deleteContributor(id, contributorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить проект без возможности восстановления. Данная операция доступна только для админов сервиса.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProject(id: string, options?: any) {
        return ProjectApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить проект по уникальному идентификатору.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(id: string, options?: any) {
        return ProjectApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить все доступные проекты для пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(options?: any) {
        return ProjectApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Применить указанный модификатор ко всем вкладам в выбранном проекте.
     * @param {string} id 
     * @param {UpdateProjectRewardsInput} [updateProjectRewardsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modifyProjectRewards(id: string, updateProjectRewardsInput?: UpdateProjectRewardsInput, options?: any) {
        return ProjectApiFp(this.configuration).modifyProjectRewards(id, updateProjectRewardsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменить свойства участника в проекте.
     * @param {string} id 
     * @param {string} contributorId 
     * @param {UpdateContributorInput} [updateContributorInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateContributor(id: string, contributorId: string, updateContributorInput?: UpdateContributorInput, options?: any) {
        return ProjectApiFp(this.configuration).updateContributor(id, contributorId, updateContributorInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить данные по проекту. Данная операция доступна только для админов сервиса.
     * @param {string} id 
     * @param {UpdateProjectInput} [updateProjectInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProject(id: string, updateProjectInput?: UpdateProjectInput, options?: any) {
        return ProjectApiFp(this.configuration).updateProject(id, updateProjectInput, options).then((request) => request(this.axios, this.basePath));
    }
}


