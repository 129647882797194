import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { observer } from 'mobx-react';

export interface IAddFpiContributionProps {
    userId: string;
    isVisible: boolean;
    onDialogClose: (isSuccess: boolean) => void;
}

interface IAddFpiContributionState {
    isBusy: boolean;
    isVisible: boolean;
}

export const AddFpiContributionDialog: React.FC<IAddFpiContributionProps> = observer(
    (props: IAddFpiContributionProps) => {
        const [state, setState] = useState<IAddFpiContributionState>({ isBusy: false, isVisible: props.isVisible });
        useEffect(() => {
            setState((s) => ({ ...s, isVisible: props.isVisible }));
        }, [props, props.isVisible]);

        const handleClose = useCallback(() => {
            props.onDialogClose(false);
            setState((s) => ({ ...s, isVisible: false, isBusy: false }));
        }, []);

        const handleSuccess = useCallback(() => {
            props.onDialogClose(true);
            setState((s) => ({ ...s, isVisible: false, isBusy: false }));
        }, []);

        return (
            <Dialog
                onSubmit={handleSuccess}
                open={state.isVisible}
                disableBackdropClick={state.isBusy}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Добавить вклад в проект</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        disabled={state.isBusy}
                        margin="dense"
                        id="project"
                        label="Проект"
                        type="project"
                        fullWidth
                    />
                    <TextField
                        disabled={state.isBusy}
                        margin="dense"
                        id="description"
                        label="Описание"
                        type="description"
                        fullWidth
                    />
                    <TextField
                        disabled={state.isBusy}
                        margin="dense"
                        id="hours"
                        label="Время (часов)"
                        type="hours"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={state.isBusy} onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button type="submit" disabled={state.isBusy} onClick={handleSuccess} color="primary">
                        Отправить
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);
