import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useActions, IEmailSetupDialogProps } from "./actions";
import { TextInputEvent } from '../../StaticTypes';


export default function EmailSetupDialog(props: IEmailSetupDialogProps) {
    const { isBusy, state, setState, handleClose, handleSuccess } = useActions(props);

    const updateEmail = (e: TextInputEvent) => {
        e.persist();
        setState((v) => ({
            ...v,
            email: e.target.value,
        }));
    };

    return (
        <Dialog
            onSubmit={handleSuccess}
            open={state.isVisible}
            disableBackdropClick={isBusy}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Запросить подтверждение/смену адреса электронной почты
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Укажите вашу актуальную электронную почту для того, 
                    чтобы мы выслали вам письмо с ссылкой для подтверждения этой почты.
                </DialogContentText>
                <TextField
                    autoFocus
                    disabled={isBusy}
                    margin="dense"
                    id="name"
                    label="Электронная почта"
                    type="email"
                    fullWidth
                    error={!!state.emailError}
                    helperText={state.emailError}
                    defaultValue={
                        state.isActualEmailVerified ? "" : state.email
                    }
                    onChange={updateEmail}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isBusy}
                    onClick={handleClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    type="submit"
                    disabled={isBusy}
                    onClick={handleSuccess}
                    color="primary"
                >
                    Отправить письмо
                </Button>
            </DialogActions>
        </Dialog>
    );
}
