import React from 'react';
import { observer } from 'mobx-react';
import { Header } from '../../Components/Header/Header';
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    TextField,
} from '@material-ui/core';
import { useStores } from '../../Services/serviceRegistration';
import { runInAction } from 'mobx';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useGameManagementStyles } from './GameManagementStyles';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import { Autocomplete } from '@material-ui/lab';
import { AllProjectRoles, projectRoleToRussian } from '../../Models/FpiGame/ProjectRoleEnumHelper';

export const PlayerEditor: React.FC = observer(() => {
    const { gameAdminStore } = useStores();
    const classes = useGameManagementStyles();
    return (
        <Grid container className={classes.inheritHeight}>
            <Grid item md={4} xs={12} className={classes.inheritHeight}>
                <List
                    className={classes.listRoot}
                    subheader={
                        <ListSubheader component="div" className={classes.listSubheader}>
                            Список участников проекта:
                            <Button
                                className={classes.button}
                                size={'small'}
                                startIcon={<AddIcon fontSize={'small'} />}
                                title={'Добавить участника'}
                                variant={'outlined'}
                                disabled={isNullOrUndefined(gameAdminStore.selectedPlayer)}
                                onClick={() => {
                                    runInAction(() => (gameAdminStore.selectedPlayer = undefined));
                                }}
                            />
                        </ListSubheader>
                    }
                >
                    {gameAdminStore.selectedProject!.contributors?.map((c) => (
                        <ListItem
                            button
                            selected={c.id === gameAdminStore.selectedPlayer?.id}
                            key={c.id}
                            onClick={() => {
                                runInAction(() => (gameAdminStore.selectedPlayer = c));
                            }}
                        >
                            <ListItemText
                                primary={`${c.lastName} ${c.firstName}`}
                                secondary={
                                    c.id === gameAdminStore.selectedProject?.creator?.id
                                        ? 'Создатель'
                                        : projectRoleToRussian(c.projectRole)
                                }
                            />
                            <IconButton
                                disabled={gameAdminStore.isBusy}
                                edge="end"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isNullOrUndefined(gameAdminStore.selectedProject)) {
                                        return;
                                    }
                                    void gameAdminStore.removeContributor(gameAdminStore.selectedProject, c);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item md={8} xs={12} className={classes.inheritHeight}>
                {gameAdminStore.selectedPlayer === undefined ? (
                    <List
                        className={classes.listRoot}
                        subheader={
                            <ListSubheader component="div" className={classes.listSubheader}>
                                Список всех игроков:
                            </ListSubheader>
                        }
                    >
                        {gameAdminStore.users?.map((player) => (
                            <ListItem key={player.id}>
                                <ListItemText primary={`${player.lastName} ${player.firstName}`} secondary="Участник" />
                                {gameAdminStore.selectedProject?.contributors?.find(
                                    (contributor) => contributor.id === player.id,
                                ) === undefined && (
                                    <IconButton
                                        edge="end"
                                        disabled={gameAdminStore.isBusy}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (isNullOrUndefined(gameAdminStore.selectedProject)) {
                                                return;
                                            }
                                            const { role, ...user } = player;
                                            void gameAdminStore.addContributor(gameAdminStore.selectedProject, user);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                )}
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <>
                        <Card style={{ margin: '1rem' }}>
                            <Header title={'Просмотр участника'} />
                            <CardContent>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    id="user-full-name"
                                    label="ФИО"
                                    value={`${gameAdminStore.selectedPlayer.lastName} ${gameAdminStore.selectedPlayer.firstName} ${gameAdminStore.selectedPlayer.patronymic}`}
                                />
                            </CardContent>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    id="user-email"
                                    label="Email"
                                    value={gameAdminStore.selectedPlayer.userEmail}
                                />
                            </CardContent>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    id="user-phone-number"
                                    label="Телефон"
                                    value={gameAdminStore.selectedPlayer.phoneNumber}
                                />
                            </CardContent>
                            {gameAdminStore.selectedPlayer.id !== gameAdminStore.selectedProject?.creator?.id && (
                                <>
                                    <Header
                                        title={'Настройки участника'}
                                        buttonTitle={'Сохранить'}
                                        isButtonDisabled={
                                            gameAdminStore.isBusy ||
                                            gameAdminStore.isSaved(gameAdminStore.selectedPlayer?.id)
                                        }
                                        onButtonClick={() => {
                                            if (
                                                isNullOrUndefined(gameAdminStore.selectedPlayer) ||
                                                isNullOrUndefined(gameAdminStore.selectedProject)
                                            ) {
                                                return;
                                            }
                                            void gameAdminStore.updatePlayer(
                                                gameAdminStore.selectedProject,
                                                gameAdminStore.selectedPlayer,
                                            );
                                        }}
                                    />
                                    <CardContent>
                                        <Autocomplete
                                            disablePortal
                                            id="contribution-project-role"
                                            options={AllProjectRoles}
                                            value={gameAdminStore.selectedPlayer.projectRole}
                                            getOptionLabel={(option) => projectRoleToRussian(option)}
                                            getOptionSelected={(option, value) => option === value}
                                            onChange={(event, value) => {
                                                runInAction(() => {
                                                    if (
                                                        isNotNullOrUndefined(value) &&
                                                        isNotNullOrUndefined(gameAdminStore.selectedPlayer)
                                                    ) {
                                                        gameAdminStore.selectedPlayer.projectRole = value;
                                                        gameAdminStore.setSaveStatus(
                                                            gameAdminStore.selectedPlayer?.id,
                                                            false,
                                                        );
                                                    }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField variant={'outlined'} {...params} label="Роль в проекте" />
                                            )}
                                        />
                                    </CardContent>
                                </>
                            )}
                        </Card>
                    </>
                )}
            </Grid>
        </Grid>
    );
});
