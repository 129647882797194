import React, { ReactNode, useEffect, useState } from 'react';
import { useStores } from '../../Services/serviceRegistration';
import { observer } from 'mobx-react';
import { UserRole } from '../../Api';

export class PermissionSupportProps {
    userRole?: UserRole;
    allow?: UserRole[];
    deny?: UserRole[];
    children?: ReactNode;
    loadingChildren?: ReactNode;
    noAccessChildren?: ReactNode;
    redirect?: string;
    isLoading?: boolean;
}

export const PermissionSupport = observer((props: PermissionSupportProps) => {
    const [isLoading, setLoadingState] = useState(true);
    const [isHaveAccess, setAccess] = useState(false);
    const { userStore } = useStores();

    const resolveAccess = (userRole: UserRole): boolean => {
        if (!!props.deny && props.deny.find((v) => v === userRole)) {
            return false;
        }
        if (!!props.allow && props.allow.find((v) => v === userRole) === undefined) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        setLoadingState(true);
        if (!!userStore.currentUser) {
            const value = resolveAccess(userStore.currentUser.role);
            setAccess(value);
        } else {
        }
        setLoadingState(false);
    }, [userStore.currentUser]);

    useEffect(() => {
        if (!!props.isLoading) {
            setLoadingState(props.isLoading);
        }
    }, [props.isLoading]);

    return <>{isLoading ? props.loadingChildren : isHaveAccess ? props.children : props.noAccessChildren}</>;
});
