import React from 'react';
import { observer } from 'mobx-react';
import { Container, Typography } from '@material-ui/core';

export const NotFound: React.FC = observer(() => {
    return (
        <Container style={{ height: '90vh', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h2" gutterBottom component="div">
                404 - Страница не найдена!
            </Typography>
            <Typography variant="h3" gutterBottom component="div">
                Что-то пошло не так.
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
                Страница, которую вы запросили, не существует или вы ввели неверный URL адрес.
            </Typography>
        </Container>
    );
});
