import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../Services/serviceRegistration';
import { useGameManagementStyles } from './GameManagementStyles';
import {
    Button,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
} from '@material-ui/core';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../Helpers/utils';
import AddIcon from '@material-ui/icons/Add';
import { runInAction } from 'mobx';
import { Header } from '../../Components/Header/Header';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

export const ContributionEditor: React.FC = observer(() => {
    const { gameAdminStore } = useStores();

    const selectedContribution = useMemo(() => {
        return gameAdminStore.selectedContribution;
    }, [gameAdminStore.selectedContribution]);

    const classes = useGameManagementStyles();
    return (
        <Grid container className={classes.inheritHeight}>
            <Grid item md={4} xs={12} className={classes.inheritHeight}>
                <List
                    className={classes.listRoot}
                    subheader={
                        <ListSubheader component="div" className={classes.listSubheader}>
                            Список вкладов:
                            <Button
                                className={classes.button}
                                size={'small'}
                                onClick={() => {
                                    gameAdminStore.createNewContribution();
                                }}
                                disabled={
                                    isNotNullOrUndefined(gameAdminStore.selectedContribution) &&
                                    isNullOrUndefined(gameAdminStore.selectedContribution.id)
                                }
                                startIcon={<AddIcon fontSize={'small'} />}
                                title={'Добавить вклад'}
                                variant={'outlined'}
                            />
                        </ListSubheader>
                    }
                >
                    {gameAdminStore.selectedProject?.records?.length === 0 && (
                        <ListItem button>
                            <ListItemText primary="В проекте нет вклада" secondary="Создайте вклад при помощи кнопки" />
                        </ListItem>
                    )}
                    {gameAdminStore.selectedProject?.records?.map((s) => (
                        <ListItem
                            button
                            selected={s.id === gameAdminStore.selectedContribution?.id}
                            key={s.id}
                            onClick={() => {
                                runInAction(() => (gameAdminStore.selectedContribution = s));
                            }}
                        >
                            <ListItemText
                                primary={`[${s.reward}] ${s.player?.userEmail}`}
                                secondary={moment(s.lastActivity).toDate().toLocaleString()}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item md={8} xs={12}>
                {gameAdminStore.selectedContribution === undefined ? (
                    <Typography variant={'h5'} style={{ textAlign: 'center', marginTop: `2.5rem` }}>
                        Выберите вклад
                    </Typography>
                ) : (
                    <Card style={{ margin: '1rem' }}>
                        {isNullOrUndefined(gameAdminStore.selectedContribution.id) ? (
                            <Header
                                title={'Создание нового вклада'}
                                buttonTitle={'Сохранить'}
                                isButtonDisabled={gameAdminStore.isBusy}
                                onButtonClick={() => {
                                    if (isNullOrUndefined(gameAdminStore.selectedContribution)) {
                                        return;
                                    }
                                    void gameAdminStore.publishNewContribution(gameAdminStore.selectedContribution);
                                }}
                            />
                        ) : (
                            <Header
                                title={'Редактирование вклада'}
                                buttonTitle={'Сохранить'}
                                isButtonDisabled={
                                    gameAdminStore.isBusy ||
                                    gameAdminStore.isSaved(gameAdminStore.selectedContribution?.id)
                                }
                                onButtonClick={() => {
                                    if (isNullOrUndefined(gameAdminStore.selectedContribution)) {
                                        return;
                                    }
                                    void gameAdminStore.updateContribution(gameAdminStore.selectedContribution);
                                }}
                            />
                        )}
                        <CardContent>
                            <Autocomplete
                                disablePortal
                                id="contribution-player"
                                options={gameAdminStore.selectedProject!.contributors!}
                                value={gameAdminStore.selectedContribution.player}
                                getOptionLabel={(option) =>
                                    `${option.lastName} ${option.firstName} (${option.userEmail})`
                                }
                                getOptionSelected={(option, value) =>
                                    isNotNullOrUndefined(option) &&
                                    isNotNullOrUndefined(value) &&
                                    option.id === value.id
                                }
                                onChange={(event, value) => {
                                    runInAction(() => (selectedContribution!.player = value));
                                    gameAdminStore.setSaveStatus(gameAdminStore.selectedContribution?.id, false);
                                }}
                                renderInput={(params) => (
                                    <TextField variant={'outlined'} {...params} label="Участник" />
                                )}
                            />
                        </CardContent>
                        <CardContent>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                required
                                id="contribution-reward"
                                label="Вознаграждение (токены)"
                                value={selectedContribution?.reward}
                                onChange={(e) => {
                                    const value = +e.currentTarget.value;
                                    if (!isNaN(value)) {
                                        runInAction(() => (selectedContribution!.reward = value));
                                        gameAdminStore.setSaveStatus(gameAdminStore.selectedContribution?.id, false);
                                    }
                                }}
                            />
                        </CardContent>
                        {isNotNullOrUndefined(gameAdminStore.selectedContribution.id) && (
                            <CardContent>
                                <TextField
                                    fullWidth
                                    disabled
                                    variant={'outlined'}
                                    id="contribution-last-activity"
                                    label="Дата создания/изменения:"
                                    value={moment(selectedContribution?.lastActivity).toDate().toLocaleString()}
                                />
                            </CardContent>
                        )}
                    </Card>
                )}
            </Grid>
        </Grid>
    );
});
