import { useState, useEffect } from "react";
import { AlertType } from "../../Components/AlertBar";
import { validateValue, validateAll } from "../../Helpers/validationSupport";
import { alertStore, authApi, historyService } from '../../Services/serviceRegistration';
import { Routes } from '../../Components/Router/routes';
import { ResetPasswordViewModel } from '../../Api';

export class ChangePasswordFormState {
    public password?: string;
    public passwordRepeat?: string;
    public passwordError?: string;
    public passwordRepeatError?: string;
}

export interface IChangePasswordPageQuery {
    token?: string;
    email?: string;
    id?: string;
}

export function useActions() {    
    const [firstTimeRun, setFirstTimeRunState] = useState(true);
    const [formState, setFormState] = useState(new ChangePasswordFormState());
    const [isBusy, setIsBusy] = useState(false);

    const queryString = new URLSearchParams(historyService.location.search);
    const query = {
        token: queryString.get("token"),
        email: queryString.get("email"),
        id: queryString.get("id"),
    } as IChangePasswordPageQuery;

    const handleSubmit = async () => {
        setIsBusy(true);
        try {
            if (validateAll(validatePassword(), validatePasswordRepeat())) {
                alertStore.show("Не все поля заполнены верно!", AlertType.Error);
                return;
            }

            if (query.token === undefined || query.email === undefined) {
                alertStore.show("Не удалось сменить пароль! Попробуйте повторно выслать запрос на почту!", AlertType.Error);
                return;
            }
    
            const data: ResetPasswordViewModel = {
                token: query.token,
                email: query.email,
                newPassword: formState.password!
            };

            const response = await authApi.resetPassword(data);

            if (!!response) {
                switch (response.status) {
                    case 200:
                        alertStore.show("Смена пароля произошла успешно успешна!", AlertType.Success);
                        historyService.push(Routes.Pages.Login);
                        break;
                    case 404: 
                        alertStore.show("Такого пользователя не существует!", AlertType.Error);
                        break;
                    case 400: 
                    default:
                        alertStore.show("Не удалось сменить пароль! Попробуйте повторно выслать запрос на почту!", AlertType.Error);
                        break;
                }
            } 
        } finally {
            setIsBusy(false);
        }
    };

    const validatePassword = () => validateValue(formState.password)
        .skipIf(firstTimeRun)
        .ensureNonEmpty()           
        .mustEqual(formState.passwordRepeat, "Пароли не совпадают")
        .getLastError();
        
    const validatePasswordRepeat = () => validateValue(formState.passwordRepeat)
        .skipIf(firstTimeRun)
        .ensureNonEmpty()
        .mustEqual(formState.password, "Пароли не совпадают")
        .getLastError();

    useEffect(() => {
        setFirstTimeRunState(false);
    }, []);

    useEffect(() => {
        setFormState(v => ({ ...v, passwordError: validatePassword(), passwordRepeatError: validatePasswordRepeat() }));
    }, [formState.password, formState.passwordRepeat]);

    return { query, formState, setFormState, handleSubmit, isBusy };
}
