import { useEffect, useState } from 'react';
import { AlertType } from '../../Components/AlertBar';
import { User } from '../../Models/Profile/User';
import { alertStore, authApi, authService, profileApi } from '../../Services/serviceRegistration';

export class ProfilePageState {
    public emailConfirmationDialogVisibility: boolean = false;
    public changeUsernameDialogVisibility: boolean = false;
    public isBusy: boolean = false;
    public userProfile?: User;
}

export function useActions() {
    const [state, setState] = useState<ProfilePageState>(new ProfilePageState());

    useEffect(() => {
        void authService.fetchUserProfile().then((u) => setState((s) => ({ ...s, userProfile: u })));
    }, []);

    const onSuccessEmailChangeModal = async (email: string): Promise<boolean> => {
        if (!!state.userProfile) {
            let response = undefined;
            if (!state.userProfile.emailConfirmed) {
                if (state.userProfile.userEmail === email) {
                    response = await authApi.sendEmailConfirmationLink();
                } else {
                    response = await authApi.unauthorizeChangeEmail({
                        newEmail: email,
                    });
                    if (response.status === 200) {
                        setState((s) => ({
                            ...s,
                            userProfile: !!s.userProfile ? { ...s.userProfile, userEmail: email } : undefined,
                        }));
                        response = await authApi.sendEmailConfirmationLink();
                    }
                }
            } else {
                if (state.userProfile.userEmail === email) {
                    alertStore.show('Вы уже подтвердили этот адрес электронной почты!', AlertType.Error);
                    return false;
                } else {
                    response = await profileApi.changeEmail({ newEmail: email });
                }
            }

            if (!response) {
                alertStore.show(`Что-то пошло не так, попробуйте еще раз!`, AlertType.Error);
            } else {
                switch (response.status) {
                    case 200:
                        const message = state.userProfile.emailConfirmed
                            ? `Письмо с запросом на смену адреса эл. почты было отправлено на почту ${email}!`
                            : `Письмо с подтверждением адреса эл. почты было отправлено на почту ${email}!`;
                        alertStore.show(message, AlertType.Success);
                        break;
                    case 409:
                        alertStore.show(
                            `Данный адрес (${email}) уже занят другим пользователем, попробуйте другой!`,
                            AlertType.Error,
                        );
                        break;
                    default:
                        alertStore.show(`Что-то пошло не так, попробуйте еще раз!`, AlertType.Error);
                        break;
                }
            }
        }
        setState((s) => ({ ...s, emailConfirmationDialogVisibility: false }));
        return false;
    };

    const onSuccessUsernameChange = async (username: string): Promise<boolean> => {
        if (!!state.userProfile) {
            if (state.userProfile.userName === username) {
                alertStore.show(`Этот никнейм уже занят вами!`, AlertType.Error);
            } else {
                const response = await profileApi.changeUserName({
                    newUserName: username,
                });
                if (!response) {
                    alertStore.show(`Что-то пошло не так, попробуйте еще раз!`, AlertType.Error);
                } else {
                    switch (response.status) {
                        case 200:
                            await authService.setToken(response.data.tokenValue!);
                            await authService.fetchUserProfile(true);
                            alertStore.show(
                                `Ваш никнейм успешно был сменен на ${username}!`,
                                AlertType.Success,
                            );
                            setState((s) => ({
                                ...s,
                                userProfile: !!s.userProfile ? { ...s.userProfile, userName: username } : undefined,
                            }));
                            break;
                        case 409:
                            alertStore.show(
                                `Данный никнейм (${username}) уже занят другим пользователем, попробуйте другой!`,
                                AlertType.Error,
                            );
                            break;
                        default:
                            alertStore.show(`Что-то пошло не так, попробуйте еще раз!`, AlertType.Error);
                            break;
                    }
                }
            }
        }
        setState((s) => ({ ...s, changeUsernameDialogVisibility: false }));
        return false;
    };

    const closeModals = async (): Promise<boolean> => {
        setState((s) => ({ ...s, emailConfirmationDialogVisibility: false, changeUsernameDialogVisibility: false }));
        return false;
    };

    return {
        state,
        setState,
        onSuccessEmailChangeModal,
        onSuccessUsernameChange,
        closeModals,
    };
}
