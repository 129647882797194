import { goTo } from '../Helpers/history';
import { Token } from '../Models/Profile/Token';
import { User } from '../Models/Profile/User';
import { UserStore } from '../Stores/UserStore';
import { AuthApi, ProfileApi } from '../Api';

export class AuthService {
    public static readonly jwtTokenKey = 'jwt_token';
    private profileGetTask?: Promise<User | undefined>;
    private authApi: AuthApi;
    private _userStore: UserStore;
    private profileApi: ProfileApi;

    public constructor(authApi: AuthApi, profileApi: ProfileApi, userStore: UserStore) {
        this.profileApi = profileApi;
        this.authApi = authApi;
        this._userStore = userStore;
    }

    public static getJwtToken = (): string | null => localStorage.getItem(AuthService.jwtTokenKey);

    public getToken(): Token | undefined {
        const tokenString = AuthService.getJwtToken();
        if (!!tokenString) {
            const token = this.parseJwt(tokenString);
            if (!!token) {
                return token;
            }
        }
        return undefined;
    }

    public async setToken(tokenString: string | undefined): Promise<void> {
        if (!!tokenString) {
            const token = this.parseJwt(tokenString);
            if (!!token) {
                localStorage.setItem(AuthService.jwtTokenKey, tokenString);
            }
        }
    }

    public async doLogout(redirectPage: string): Promise<void> {
        const response = await this.authApi.logout();
        if (!!response && response?.status === 200) {
            localStorage.removeItem(AuthService.jwtTokenKey);
            this.profileGetTask = undefined;
            this._userStore.setUser(false, undefined);
            goTo(redirectPage);
        }
    }

    private async resolveUserProfile(): Promise<User | undefined> {
        if (localStorage.getItem(AuthService.jwtTokenKey) === null) {
            return undefined;
        }
        const response = await this.profileApi.getProfile();
        switch (response.status) {
            case 200:
            case 209:
                const user = this._userStore.setUser(true, response.data);
                if (response.status === 209) {
                    await this.setToken(user?.token);
                }
                return user;
        }
        return undefined;
    }

    public fetchUserProfile(forceUpdate: boolean = false): Promise<User | undefined> {
        if (this.profileGetTask === undefined || forceUpdate) {
            this.profileGetTask = this.resolveUserProfile();
        } else if (this._userStore.isUserProfileLoaded) {
            return Promise.resolve(this._userStore.currentUser);
        }
        return this.profileGetTask;
    }

    public parseJwt(token: string): Token | undefined {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''),
            );
            const tokenSource = JSON.parse(jsonPayload);
            return new Token(tokenSource);
        } catch (e) {
            console.error('Не удается распознать токен.');
        }
        return undefined;
    }
}
